import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import config from '../config';
import { SUPPORTED_LANGUAGES } from '../i18n/i18n';
import '../styles/login.css';

const FeatureCard = ({ icon, title, description }) => (
    <motion.div 
        className="feature-card"
        whileHover={{ scale: 1.05 }}
        transition={{ type: "spring", stiffness: 300 }}
    >
        <div className="feature-icon">{icon}</div>
        <h4>{title}</h4>
        <p>{description}</p>
    </motion.div>
);

const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [isGoogleSignInReady, setIsGoogleSignInReady] = useState(false);
    const buttonContainerRef = useRef(null);
    const { t, i18n } = useTranslation();

    const searchParams = new URLSearchParams(location.search);
    const nextUrl = searchParams.get('nextUrl') || '/home';

    const handleCredentialResponse = useCallback((response) => {
        if (!response?.credential) {
            console.error('No credential received');
            return;
        }

        try {
            const credential = response.credential;
            const base64Url = credential.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            const decodedToken = JSON.parse(window.atob(base64));

            Cookies.set('user_email', decodedToken.email, { 
                expires: 7, 
                secure: true, 
                sameSite: 'strict' 
            });
            Cookies.set('google_id_token', credential, { 
                expires: 7, 
                secure: true, 
                sameSite: 'strict' 
            });

            navigate(nextUrl, { replace: true });
        } catch (error) {
            console.error('Authentication failed:', error);
            alert(t('loginPage.errors.authFailed'));
        }
    }, [navigate, nextUrl, t]);

    const initializeGoogleSignIn = useCallback(() => {
        if (!window.google?.accounts?.id || !isGoogleScriptLoaded || !buttonContainerRef.current) {
            return;
        }

        try {
            // Clear any existing buttons
            if (buttonContainerRef.current) {
                buttonContainerRef.current.innerHTML = '';
            }

            window.google.accounts.id.initialize({
                client_id: config.googleClientId,
                callback: handleCredentialResponse,
                auto_select: false,
                cancel_on_tap_outside: true,
                use_fedcm_for_prompt: true // Enable FedCM
            });

            // Render the button only if the container exists
            if (buttonContainerRef.current) {
                window.google.accounts.id.renderButton(
                    buttonContainerRef.current,
                    {
                        theme: 'filled_pink',
                        size: 'large',
                        type: 'standard',
                        shape: 'rectangular',
                        logo_alignment: 'center',
                        width: 300
                    }
                );
            }

            setIsGoogleSignInReady(true);
        } catch (error) {
            console.error('Failed to initialize Google Sign-In:', error);
            setIsGoogleSignInReady(false);
        }
    }, [handleCredentialResponse, isGoogleScriptLoaded]);

    const handleCustomLogin = () => {
        if (!isGoogleSignInReady || !window.google?.accounts?.id) {
            console.log('Reinitializing Google Sign-In...');
            initializeGoogleSignIn();
            return;
        }

        try {
            window.google.accounts.id.prompt();
        } catch (error) {
            console.error('Failed to prompt Google Sign-In:', error);
            initializeGoogleSignIn();
        }
    };

    useEffect(() => {
        const token = Cookies.get('google_id_token');
        if (token) {
            navigate(nextUrl, { replace: true });
            return;
        }

        let scriptElement = null;
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        script.onload = () => {
            setIsGoogleScriptLoaded(true);
            initializeGoogleSignIn();
        };
        script.onerror = () => {
            console.error('Failed to load Google Sign-In script');
            setIsGoogleScriptLoaded(false);
        };

        document.body.appendChild(script);
        scriptElement = script;

        return () => {
            if (window.google?.accounts?.id) {
                try {
                    window.google.accounts.id.cancel();
                } catch (error) {
                    console.error('Error cleaning up Google Sign-In:', error);
                }
            }
            
            try {
                if (scriptElement && document.body.contains(scriptElement)) {
                    document.body.removeChild(scriptElement);
                }
            } catch (error) {
                console.error('Error removing script element:', error);
            }
        };
    }, [navigate, nextUrl, initializeGoogleSignIn]);

    // Re-initialize when the container ref is available
    useEffect(() => {
        if (buttonContainerRef.current && isGoogleScriptLoaded) {
            initializeGoogleSignIn();
        }
    }, [initializeGoogleSignIn, isGoogleScriptLoaded]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('i18nextLng', lng);
    };

    const features = [
        {
            icon: '🔍',
            title: t('loginPage.features.personalizedInsights.title'),
            description: t('loginPage.features.personalizedInsights.description')
        },
        {
            icon: '📊',
            title: t('loginPage.features.styleAnalytics.title'),
            description: t('loginPage.features.styleAnalytics.description')
        },
        {
            icon: '🌐',
            title: t('loginPage.features.seamlessExperience.title'),
            description: t('loginPage.features.seamlessExperience.description')
        }
    ];

    return (
        <div className="login-container" dir={i18n.language === 'he' ? 'rtl' : 'ltr'}>
            <div className="language-selector">
                {SUPPORTED_LANGUAGES.map((lng) => (
                    <button 
                        key={lng} 
                        onClick={() => changeLanguage(lng)}
                        className={i18n.language === lng ? 'active' : ''}
                    >
                        {t(`languages.${lng}`)}
                    </button>
                ))}
            </div>
            <motion.div 
                className="login-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <div className="login-header">
                    <img 
                        src="/images/decorative.svg" 
                        alt="Magic Mirror AI Logo" 
                        className="logo" 
                    />
                    <h1>{t('loginPage.title')}</h1>
                    <p>{t('loginPage.subtitle')}</p>
                </div>

                <div className="login-body">
                    <button 
                        className="custom-login-button"
                        onClick={handleCustomLogin}
                    >
                        {t('loginPage.customLoginButton')}
                    </button>
                    <div className="login-description-text">
                        <h2>{t('loginPage.whySignIn.title')}</h2>
                        <p>{t('loginPage.whySignIn.description')}</p>
                    </div>

                    <div className="login-action-row">
                        <div className="login-action">
                            <div 
                                ref={buttonContainerRef}
                                className="google-signin-button-container"
                            />
                            <p className="login-disclaimer">
                                {t('loginPage.disclaimer')}
                            </p>
                        </div>
                    </div>

                    <div className="login-features">
                        <div className="feature-grid">
                            {features.map((feature, index) => (
                                <FeatureCard 
                                    key={index}
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            ))}
                        </div>

                        {!showDetails && (
                            <motion.button 
                                className="details-toggle"
                                onClick={() => setShowDetails(true)}
                                whileHover={{ scale: 1.05 }}
                                transition={{ type: "spring", stiffness: 300 }}
                            >
                                {t('loginPage.learnMoreAboutData')}
                            </motion.button>
                        )}

                        <AnimatePresence>
                            {showDetails && (
                                <motion.div 
                                    className="data-details"
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: 'auto' }}
                                    exit={{ opacity: 0, height: 0 }}
                                >
                                    <h3>{t('loginPage.dataControl.title')}</h3>
                                    <ul>
                                        {[
                                            'loginPage.dataControl.point1',
                                            'loginPage.dataControl.point2', 
                                            'loginPage.dataControl.point3'
                                        ].map((point, index) => (
                                            <li key={index}>{t(point)}</li>
                                        ))}
                                    </ul>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default LoginPage;
