// src/pages/AddItemPage.js

import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import ApiService from '../services/apiService';
import TopMenu from '../components/TopMenu';
import Message from '../components/Message';
import useAutoDismissMessage from '../hooks/useAutoDismissMessage';
import { useTranslation } from 'react-i18next';
import '../styles/pages/addItem.css';

const AddItemPage = () => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [images, setImages] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [taskId, setTaskId] = useState(null);
    const [language, setLanguage] = useState('en');
    const [newItems, setNewItems] = useState([]);

    // Use the custom hook for messages
    const { message, type, showMessage, clearMessage } = useAutoDismissMessage();
    const { t } = useTranslation();

    const handleUpload = (event) => {
        const files = Array.from(event.target.files);
        if (files) {
            setImages((prevImages) => [...prevImages, ...files]);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await ApiService.updateInventory(images);
            setTaskId(response.task_id);
            setNewItems(response.items);
            showMessage(t('messages.addItem.submitSuccess'), 'success');
        } catch (error) {
            console.error('Error uploading photos:', error);
            showMessage(t('messages.addItem.uploadError'), 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (index, field, value) => {
        setNewItems((prevItems) => {
            const updatedItems = [...prevItems];
            if (field === 'category' || field === 'color' || field === 'pattern') {
                updatedItems[index].description[field] = value;
            } else {
                updatedItems[index][field] = value;
            }
            return updatedItems;
        });
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const response = await ApiService.saveUpdatedItems(taskId, newItems);
            console.log('Items saved:', response);
            const messageText = response.message || t('messages.addItem.saveSuccess');
            showMessage(messageText, 'success');
            navigate('/inventory');
        } catch (error) {
            console.error('Error saving items:', error);
            showMessage(t('messages.addItem.saveError'), 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main-container d-flex flex-column align-items-center justify-content-start text-center p-3">
            <TopMenu language={language} setLanguage={setLanguage} />
            <h2>{t('addItem.title')}</h2>
            {message && <Message message={message} type={type} />}
            <p>{t('addItem.description')}</p>
            <label className="btn btn-secondary">
                <FontAwesomeIcon icon={faUpload} />
                <input type="file" accept="image/*" multiple onChange={handleUpload} style={{ display: 'none' }} />
            </label>
            <div className="images-preview">
                {images.map((image, index) => (
                    <img key={index} src={URL.createObjectURL(image)} alt={`Outfit ${index + 1}`} />
                ))}
            </div>
            <div className="controls">
                <button className="btn btn-success" onClick={handleSubmit} disabled={loading}>
                    {loading ? t('addItem.analyzing') : t('addItem.analyze')}
                </button>
            </div>
            <div className="matched-items">
                {newItems.length > 0 && (
                    <table className="table table-dark">
                        <thead>
                        <tr>
                            <th>{t('addItem.name')}</th>
                            <th>{t('addItem.type')}</th>
                            <th>{t('addItem.category')}</th>
                            <th>{t('addItem.color')}</th>
                            <th>{t('addItem.pattern')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {newItems.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="text"
                                        value={item.name}
                                        onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={item.type}
                                        onChange={(e) => handleInputChange(index, 'type', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={item.description.category}
                                        onChange={(e) => handleInputChange(index, 'category', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={item.description.color}
                                        onChange={(e) => handleInputChange(index, 'color', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={item.description.pattern}
                                        onChange={(e) => handleInputChange(index, 'pattern', e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>
            {newItems.length > 0 && (
                <div className="controls">
                    <button className="btn btn-primary" onClick={handleSave} disabled={loading}>
                        {loading ? t('addItem.saving') : t('addItem.save')}
                    </button>
                </div>
            )}
        </div>
    );
};

export default AddItemPage;
