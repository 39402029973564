import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import TopMenu from '../components/TopMenu';
import ApiService from '../services/apiService';
import '../styles/pages/outfitHistoryPage.css';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faImage, 
    faCalendar, 
    faTag, 
    faFilter, 
    faSearch 
} from '@fortawesome/free-solid-svg-icons';

const OutfitHistoryPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    // Filters
    const [filters, setFilters] = useState({
        taskType: '',
        eventType: ''
    });

    const [searchTerm, setSearchTerm] = useState('');

    const [expandedTexts, setExpandedTexts] = useState({});

    const fetchResults = useCallback(async () => {
        try {
            setLoading(true);
            const data = await ApiService.getHistoricalResults(page);
            
            if (data.results.length === 0) {
                setHasMore(false);
            } else {
                setResults(prev => [...prev, ...data.results]);
            }
        } catch (error) {
            console.error('Failed to fetch historical results:', error);
        } finally {
            setLoading(false);
        }
    }, [page]);

    useEffect(() => {
        fetchResults();
    }, [fetchResults]);

    useEffect(() => {
        const handleScroll = () => {
            const bottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
            if (bottom && !loadingMore) {
                handleMoreClick();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loadingMore]);

    const handleMoreClick = async () => {
        if (!loadingMore) {
            setLoadingMore(true);
            setPage(prev => prev + 1);
            await fetchResults();
            setLoadingMore(false);
        }
    };

    const handleResultClick = (taskId) => {
        navigate(`/result/${taskId}`);
    };

    const toggleTextExpand = (taskId, event) => {
        event.stopPropagation();
        setExpandedTexts(prev => ({
            ...prev,
            [taskId]: !prev[taskId]
        }));
    };

    const filteredResults = results.filter(result => {
        const matchesTaskType = !filters.taskType || result.task_type === filters.taskType;
        const matchesEventType = !filters.eventType || result.event_type === filters.eventType;
        const matchesSearch = !searchTerm || 
            result.text.toLowerCase().includes(searchTerm.toLowerCase());

        return matchesTaskType && matchesEventType && matchesSearch;
    });

    const renderFilterSection = () => (
        <motion.div 
            className="filter-section"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
        >
            <div className="search-container">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                <input 
                    type="text" 
                    placeholder={t('outfitHistoryPage.searchPlaceholder')} 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>

            <div className="filter-dropdowns">
                <select 
                    value={filters.taskType} 
                    onChange={(e) => setFilters(prev => ({ ...prev, taskType: e.target.value }))}
                >
                    <option value="">{t('outfitHistoryPage.filter.allMagicTypes')}</option>
                    <option value="mirror">{t('outfitHistoryPage.filter.magicMirror')}</option>
                    <option value="dresser">{t('outfitHistoryPage.filter.magicDresser')}</option>
                    <option value="shop">{t('outfitHistoryPage.filter.magicShop')}</option>
                    <option value="judge">{t('outfitHistoryPage.filter.magicJudge')}</option>
                    <option value="outfit">{t('outfitHistoryPage.filter.magicOutfit')}</option>
                    <option value="chooser">{t('outfitHistoryPage.filter.magicChooser')}</option>
                    <option value="measurement">{t('outfitHistoryPage.filter.magicMeasurement')}</option>
                </select>

                <select 
                    value={filters.eventType} 
                    onChange={(e) => setFilters(prev => ({ ...prev, eventType: e.target.value }))}
                >
                    <option value="">{t('outfitHistoryPage.filter.allEvents')}</option>
                    <option value="casual">{t('outfitHistoryPage.filter.casual')}</option>
                    <option value="formal">{t('outfitHistoryPage.filter.formal')}</option>
                    <option value="party">{t('outfitHistoryPage.filter.party')}</option>
                </select>
            </div>
        </motion.div>
    );

    const renderResultCard = (result, index) => {
        // Safely extract first image URL, handling different possible formats
        const imageUrl = Array.isArray(result.images) 
            ? result.images[0] 
            : (typeof result.images === 'string' 
                ? result.images 
                : (result.images?.url || result.images?.link || ''));

        // Safely extract text, with fallback
        const resultText = typeof result.text === 'string' 
            ? result.text 
            : (result.text?.description || 'No description available');

        // Safely extract style
        const resultStyle = typeof result.style === 'string' 
            ? result.style 
            : (result.style?.name || 'Unknown Style');

        // Safely parse date
        const formattedDate = result.date 
            ? new Date(result.date).toLocaleDateString() 
            : 'Unknown Date';

        return (
            <motion.div 
                key={`${result.task_id}-${index}`}
                className="result-card"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ 
                    duration: 0.3, 
                    delay: index * 0.1 
                }}
                whileHover={{ scale: 1.05 }}
                onClick={() => handleResultClick(result.task_id)}
            >
                <div className="result-image-container">
                    {imageUrl ? (
                        <img 
                            src={imageUrl} 
                            alt="Outfit Result" 
                            className="result-image" 
                        />
                    ) : (
                        <div className="no-image-placeholder">No Image Available</div>
                    )}
                </div>
                <div className="result-details">
                    <div className="result-meta">
                        <span><FontAwesomeIcon icon={faTag} /> {resultStyle}</span>
                        <span>
                            <FontAwesomeIcon icon={faCalendar} /> 
                            {formattedDate}
                        </span>
                    </div>
                    <p 
                        className={`result-text ${expandedTexts[result.task_id] ? 'expanded' : ''}`}
                        onClick={(e) => toggleTextExpand(result.task_id, e)}
                    >
                        {resultText}
                    </p>
                </div>
            </motion.div>
        );
    };

    return (
        <div className="outfit-history-page">
            <TopMenu />
            
            <motion.div 
                className="page-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <h1 className="page-title">
                    <FontAwesomeIcon icon={faImage} /> {t('outfitHistoryPage.title')}
                </h1>

                {renderFilterSection()}

                <div className="results-grid">
                    <AnimatePresence>
                        {filteredResults.map((result, index) => renderResultCard(result, index))}
                    </AnimatePresence>
                </div>

                {loading && <div className="loading-spinner">{t('outfitHistoryPage.loadingMessage')}</div>}
                {!loading && filteredResults.length === 0 && (
                    <motion.div 
                        className="no-results-message"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                    >
                        {t('outfitHistoryPage.noResultsMessage')}
                    </motion.div>
                )}
            </motion.div>
        </div>
    );
};

export default OutfitHistoryPage;
