// File: src/services/apiService.js
import axiosInstance from '../axiosConfig';
import config from '../config';

class ApiService {
  static async uploadPhoto(images, style, language, event_type = null) {

    const formData = new FormData();
    formData.append('file', images[0], 'selfie.jpg');
    formData.append('style', style);
    formData.append('language', language);
    if (event_type) {
      formData.append('event_type', event_type);
    }

    const response = await axiosInstance.post(`${config.backend_hostname}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data;
  }

  static async compareOutfits(images, style, language, event_type) {
    const formData = new FormData();
    images.forEach((blob, index) => {
      formData.append('files', blob, `outfit${index + 1}.jpg`);
    });
    formData.append('language', language);
    formData.append('event_type', event_type);
    console.log('event_type:', event_type);

    // assert event type is not null
    if (!event_type) {
        throw new Error('Event type must be provided.');
    }


    const response = await axiosInstance.post(`${config.backend_hostname}/outfit/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data;
  }

  static async updateInventory(blobs) {
    const formData = new FormData();
    blobs.forEach((blob, index) => {
      formData.append('files', blob, `item${index + 1}.jpg`);
    });

    const response = await axiosInstance.post(`${config.backend_hostname}/inventory/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }

  static async getInventory() {
    const response = await axiosInstance.get(`${config.backend_hostname}/inventory`, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  }

  // api_service.js
  static async saveUpdatedItems(taskId, items) {
    const data = {
      task_id: taskId,
      items: items, // Use the key 'inventory' as expected by the backend
    };

    const response = await axiosInstance.post(`${config.backend_hostname}/inventory/update`, data, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  }


  static async uploadMeasurements(images, measurements) {
    const formData = new FormData();
    images.forEach((image, index) => {
      formData.append('files', image, `measurement${index + 1}.jpg`);
    });
    formData.append('measurements', JSON.stringify(measurements));

    const response = await axiosInstance.post(`${config.backend_hostname}/measurements/evaluate`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data;
  }

  static async getMeasurements() {
    const response = await axiosInstance.get(`${config.backend_hostname}/measurements`, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  }

  static saveMeasurements(taskId, measurements) {
    const data = {
      task_id: taskId,
      measurements: measurements, // Use the key 'inventory' as expected by the backend
    }

    return axiosInstance.post(`${config.backend_hostname}/measurements/save`, data, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  /**
   * Uploads the user's profile image to the backend.
   * @param {Blob} blob - The image file blob to upload.
   * @returns {Promise<Object>} - The response data from the backend.
   */
  static async uploadProfileImage(blob) {
    const formData = new FormData();
    formData.append('file', blob, 'profile.jpg');

    try {
      const response = await axiosInstance.post(`${config.backend_hostname}/upload/profile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    } catch (error) {
      console.error('Error uploading profile image:', error);
      throw error;
    }
  }

  /**
   * Fetches the user's profile image from the backend.
   * @returns {Promise<Blob>} - The image file blob.
   */
  static async fetchProfileImage() {
    try {
      const response = await axiosInstance.get(`${config.backend_hostname}/download/profile`, {
        responseType: 'blob', // Important to receive as blob
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching profile image:', error);
      throw error;
    }
  }

  /**
   * Constructs the URL to download the user's profile image.
   * Utilizes a timestamp to prevent caching issues.
   * @param {number} timestamp - The current timestamp.
   * @returns {string} - The URL to the profile image.
   */
  static getProfileImageUrl(timestamp) {
    return `${config.backend_hostname}/download/image/profile?reload=${timestamp}`;
  }


  /**
   * 
   * @param {*} page 
   * @param {*} perPage 
   * @returns 
   */
  static async getHistoricalResults(page = 1, perPage = 10) {
  try {
    const response = await axiosInstance.get(`${config.backend_hostname}/results/get_user_results`, {
      params: {
        page,
        per_page: perPage
      },
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching historical results:', error);
    throw error;
  }
}  

  /**
   * Fetches a specific result by task ID
   * @param {string} taskId - The unique task identifier
   * @returns {Promise<Object>} - The result data
   */
  static async fetchResult(taskId) {
    try {
      const response = await axiosInstance.get(`${config.backend_hostname}/results/${taskId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch result:', error);
      throw error;
    }
  }

  /**
   * Generates the URL for a result image
   * @param {string} taskId - The unique task identifier
   * @param {number} [index=0] - The index of the image (default is first image)
   * @returns {string} - The URL for the result image
   */
  static getResultImageUrl(taskId, index = 0) {
    return `${config.backend_hostname}/download/image/${taskId}?index=${index}`;
  }

  /**
   * Generates the URL for a result audio
   * @param {string} taskId - The unique task identifier
   * @returns {string} - The URL for the result audio
   */
  static getResultAudioUrl(taskId) {
    return `${config.backend_hostname}/download/audio/${taskId}`;
  }
}

export default ApiService;
