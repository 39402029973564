// File: src/main_pages/StyleMeterPage.js
import CommonPage from "./CommonPage";
import { useTranslation } from 'react-i18next';
import MultiStepStyleMeter from './multiSteps/MultiStepStyleMeter';

const StyleMeterPage = () => {
    const { t } = useTranslation();
    const promptOptions = [
        { value: 'style_meter_whole_outfit', label: 'Everyone in the room 👥' },
        // { value: 'style_meter_multiple', label: 'With friends 🤝' },
      ];
    
    return (
        <div >
            <CommonPage
                promptOptions={promptOptions}
                fetchRecommendations={true}
                pageDescription={t('pageDescriptions.styleMeter')}
                showEventSelection={false}
                showImagePreview={true}
                showCallToActions={true}
                showPromptOptions={false}
                CustomControls={<MultiStepStyleMeter />}
            />
        </div>
    );
};

export default StyleMeterPage;
