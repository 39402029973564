import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './i18n/i18n';
import { LanguageProvider, useLanguage } from './context/LanguageContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Add global error handling
window.addEventListener('error', (event) => {
    console.error('Uncaught error:', event.error);
});

// Add promise rejection handling
window.addEventListener('unhandledrejection', (event) => {
    console.error('Unhandled promise rejection:', event.reason);
});

// Set direction based on current language
const MainApp = () => {
    const { currentLanguage } = useLanguage();
    document.documentElement.dir = currentLanguage === 'he' ? 'rtl' : 'ltr';

    return <App />;
};

root.render(
    <React.StrictMode>
        <LanguageProvider>
            <MainApp />
        </LanguageProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
