import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useSession } from '../context/SessionContext';

import TopMenu from '../components/TopMenu';
import LandingVideoContainer from '../components/LandingPageVideo';
import Controls from '../components/Controls';
import ApiService from '../services/apiService';
import MultiStepShopDresser from '../main_pages/multiSteps/MultiStepShopDresser';

const MirrorLandingPage = () => {
    const { i18n, t } = useTranslation();
    const videoContainerRef = useRef(null);
    const { sessionKey, sessionIsSet } = useSession();

    // State for managing page interactions
    const [style, setStyle] = useState('shop_welcome');
    const [event, setEvent] = useState('Casual');
    const [taskId, setTaskId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleSessionSet = () => {
        // Removed setIsVisible(true);
    };

    useEffect(() => {
        if (sessionIsSet) {
            handleSessionSet();
        }
    }, [sessionIsSet]);

    const uploadPhoto = async (images) => {
        try {
            setIsLoading(true);
            // Use uploadPhoto method with shop_welcome style
            const data = await ApiService.uploadPhoto(
                images, 
                style, 
                i18n.language, 
                event
            );

            const { task_id } = data;
            
            // Update state with response
            setTaskId(task_id);

            // Navigate to welcome animation page first, which will then redirect to results
            console.log("Navigating to welcome animation with task_id:", task_id);
            console.log("URL:", `/welcome-animation/${task_id}`);
            window.location.href = `/welcome-animation/${task_id}`;
        } catch (error) {
            console.error('Error uploading photo:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const overlayStyle = sessionKey ? { display: 'none' } : { position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'black', zIndex: 9999 };

    return (
        <motion.div 
            className="landing-page"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <div>
                <div className="black-overlay" style={overlayStyle} />
                <TopMenu />
                <div className="landing-page-content" style={sessionKey ? { opacity: 1 } : { opacity: 0 }}>
                    <h1>Welcome to Talking Mirror</h1>
                    <p>Capture your first moment with us!</p>
                    <div className="controls-video-container">
                        <LandingVideoContainer
                            ref={videoContainerRef}
                            uploadMethod={uploadPhoto}
                            actionText={t('landing.capture')}
                            autoCapture={true}
                            autoCaptureProbability={0.7}
                            showEventSelection={false}
                            showCallToActions={true}
                            CustomControls={<MultiStepShopDresser />}
                        />
                        
                    </div>
                </div>
                <div className='black-screen' style={sessionKey ? { display: 'none' } : { display: 'block' }}>
                    Black Screen
                </div>
            </div>
        </motion.div>
    );
};

export default MirrorLandingPage;
