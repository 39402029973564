import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const WelcomeAnimation = () => {
    const navigate = useNavigate();
    const { taskId } = useParams();
    const { t } = useTranslation();
    const [messageIndex, setMessageIndex] = useState(0);

    const messages = [
        t('welcome.greeting'),
        t('welcome.analyzing'),
        t('welcome.preparing'),
        t('welcome.almostReady'),
        t('welcome.ready')
    ];

    useEffect(() => {
        const messageInterval = setInterval(() => {
            setMessageIndex(prev => (prev + 1) % messages.length);
        }, 1000);

        // Redirect to results page after 5 seconds
        const timer = setTimeout(() => {
            navigate(`/result/${taskId}`);
        }, 5000);

        return () => {
            clearTimeout(timer);
            clearInterval(messageInterval);
        };
    }, [taskId, navigate, messages.length]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="welcome-animation"
            style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'linear-gradient(135deg, #1a1a1a 0%, #4a4a4a 100%)',
                color: 'white',
                overflow: 'hidden'
            }}
        >
            <AnimatePresence mode="wait">
                <motion.div
                    key={messageIndex}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                    style={{ textAlign: 'center' }}
                >
                    <motion.h1
                        style={{ 
                            fontSize: '2.5rem', 
                            marginBottom: '2rem',
                            fontWeight: '300'
                        }}
                    >
                        {messages[messageIndex]}
                    </motion.h1>
                </motion.div>
            </AnimatePresence>
            <motion.div
                animate={{
                    scale: [1, 1.2, 1],
                }}
                transition={{
                    duration: 1.5,
                    repeat: Infinity,
                }}
                style={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '50%',
                    background: 'linear-gradient(135deg, #6e8efb 0%, #ff9a9e 100%)',
                    boxShadow: '0 0 20px rgba(110, 142, 251, 0.5)'
                }}
            />
        </motion.div>
    );
};

export default WelcomeAnimation;
