import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import EventContext from '../../context/EventContext';
import config from '../../config';
import '../../styles/pages/multiStepMirror.css';

const MultiStepHelpMeChoose = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { event, setEvent } = useContext(EventContext);

    const [selectedComparison, setSelectedComparison] = useState('whole');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        // Set default event value if it's not already set
        if (!event) {
            setEvent('Casual');
        }
    }, [event, setEvent]);

    const comparisonTypes = [
        { value: 'whole', label: t('helpMeChoose.comparisonTypes.whole') },
        { value: 'price', label: t('helpMeChoose.comparisonTypes.price') },
        { value: 'style', label: t('helpMeChoose.comparisonTypes.style') },
        { value: 'brand', label: t('helpMeChoose.comparisonTypes.brand') },
        { value: 'fit', label: t('helpMeChoose.comparisonTypes.fit') }
    ];

    const handleProceed = () => {
        if (!event || !selectedComparison) {
            alert(t('helpMeChoose.selectBoth')); // Alert if both selections are not made
            return;
        }

        setIsSubmitting(true);
        navigate('/outfit-chooser', {
            state: {
                autoCapture: true,
                event: event,
                comparison: selectedComparison
            }
        });
    };

    return (
        <Container className="multi-step-mirror-container">
            <form>
                <div className="form-group">
                    <select 
                        id="eventSelect" 
                        value={event} 
                        onChange={(e) => setEvent(e.target.value)}
                        disabled={isSubmitting}
                    >
                        <option value="">{t('events.selectEvent')}</option>
                        {config.events.map(eventOption => (
                            <option key={eventOption} value={eventOption}>
                                {t(`events.predefinedEvents.${eventOption.replace(/\s+/g, '')}`, eventOption)}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <select 
                        id="comparisonSelect" 
                        value={selectedComparison} 
                        onChange={(e) => setSelectedComparison(e.target.value)}
                        disabled={isSubmitting}
                    >
                        <option value="">{t('helpMeChoose.criteria')}</option>
                        {comparisonTypes.map(type => (
                            <option key={type.value} value={type.value}>
                                {type.label}
                            </option>
                        ))}
                    </select>
                </div>

                <button 
                    type="button"
                    onClick={handleProceed} 
                    className="btn btn-gradient-pink"
                    disabled={isSubmitting}
                >
                    {t('actions.GO')}
                </button>
            </form>
        </Container>
    );
};

export default MultiStepHelpMeChoose;
