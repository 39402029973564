import React, { useState, useRef } from 'react';
import CommonPage from './CommonPage';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShirt } from '@fortawesome/free-solid-svg-icons';
import MultiStepMirror from './multiSteps/MultiStepMirror';
import { useTranslation } from 'react-i18next';

const MirrorPage = () => {
  const { t } = useTranslation();
  const promptOptions = [
    { value: 'flattering', label: 'Love me ❤️' },
    { value: 'positive', label: 'Positive 😎' },
    { value: 'negative', label: 'Honest 🤓' },
    { value: 'bitch', label: 'Nasty 🤬' },
    { value: 'stylist', label: 'Stylist 🔥' },
  ];

  return (
      <div>
        <CommonPage
            promptOptions={promptOptions}
            fetchRecommendations={true}
            pageDescription={t('pageDescriptions.mirror')}
            showEventSelection={false}
            showImagePreview={true}
            showCallToActions={true}
            showPromptOptions={false}
            CustomControls={<MultiStepMirror />}
          />
      </div>
  );
};

export default MirrorPage;
