// File: src/components/ImageConfirmation.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const ImageConfirmation = ({ onSubmit, onRetake }) => {
    const { t } = useTranslation();
    
    return (
        <div className="image-confirmation">
            <button onClick={onSubmit} className="btn btn-gradient-pink">
                {t('imageConfirmation.submit')}
            </button>
            <button onClick={onRetake} className="btn btn-gradient-pink">
                {t('imageConfirmation.retake')}
            </button>
        </div>
    );
};

export default ImageConfirmation;
