import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPlus, faList, faTags } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ApiService from '../services/apiService';
import useAutoDismissMessage from '../hooks/useAutoDismissMessage';
import '../styles/pages/wardrobe.css';

const WardrobePage = () => {
    const { t } = useTranslation();
    const [activeSection, setActiveSection] = useState('upload');
    const [images, setImages] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [newItems, setNewItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);

    // Message handling
    const { message, type, showMessage, clearMessage } = useAutoDismissMessage();

    // Constants for styling and options
    const WARDROBE_SECTIONS = [
        { id: 'upload', label: t('wardrobePage.sections.upload'), icon: faUpload },
        { id: 'inventory', label: t('wardrobePage.sections.inventory'), icon: faList },
        { id: 'categories', label: t('wardrobePage.sections.categories'), icon: faTags }
    ];

    const ITEM_CATEGORIES = [
        t('wardrobePage.categories.tops'),
        t('wardrobePage.categories.bottoms'),
        t('wardrobePage.categories.dresses'),
        t('wardrobePage.categories.outerwear'),
        t('wardrobePage.categories.shoes'),
        t('wardrobePage.categories.accessories'),
        t('wardrobePage.categories.formalWear'),
        t('wardrobePage.categories.casualWear')
    ];

    const ITEM_TYPES = {
        [t('wardrobePage.categories.tops')]: [
            t('wardrobePage.itemTypes.tops.tshirt'),
            t('wardrobePage.itemTypes.tops.shirt'),
            t('wardrobePage.itemTypes.tops.sweater'),
            t('wardrobePage.itemTypes.tops.blouse')
        ],
        [t('wardrobePage.categories.bottoms')]: [
            t('wardrobePage.itemTypes.bottoms.jeans'),
            t('wardrobePage.itemTypes.bottoms.trousers'),
            t('wardrobePage.itemTypes.bottoms.shorts'),
            t('wardrobePage.itemTypes.bottoms.skirt')
        ],
        [t('wardrobePage.categories.dresses')]: [
            t('wardrobePage.itemTypes.dresses.casual'),
            t('wardrobePage.itemTypes.dresses.evening'),
            t('wardrobePage.itemTypes.dresses.cocktail')
        ],
        [t('wardrobePage.categories.outerwear')]: [
            t('wardrobePage.itemTypes.outerwear.jacket'),
            t('wardrobePage.itemTypes.outerwear.coat'),
            t('wardrobePage.itemTypes.outerwear.blazer'),
            t('wardrobePage.itemTypes.outerwear.hoodie')
        ],
        [t('wardrobePage.categories.shoes')]: [
            t('wardrobePage.itemTypes.shoes.sneakers'),
            t('wardrobePage.itemTypes.shoes.boots'),
            t('wardrobePage.itemTypes.shoes.heels'),
            t('wardrobePage.itemTypes.shoes.sandals')
        ],
        [t('wardrobePage.categories.accessories')]: [
            t('wardrobePage.itemTypes.accessories.belt'),
            t('wardrobePage.itemTypes.accessories.scarf'),
            t('wardrobePage.itemTypes.accessories.hat'),
            t('wardrobePage.itemTypes.accessories.jewelry')
        ],
        [t('wardrobePage.categories.formalWear')]: [
            t('wardrobePage.itemTypes.formalWear.suit'),
            t('wardrobePage.itemTypes.formalWear.tuxedo'),
            t('wardrobePage.itemTypes.formalWear.gown')
        ],
        [t('wardrobePage.categories.casualWear')]: [
            t('wardrobePage.itemTypes.casualWear.loungewear'),
            t('wardrobePage.itemTypes.casualWear.athleisure'),
            t('wardrobePage.itemTypes.casualWear.streetwear')
        ]
    };

    // Section variants for animations
    const sectionVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: { 
                type: "spring", 
                stiffness: 300, 
                damping: 20 
            }
        }
    };

    // Image upload handler
    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setImages(prevImages => [...prevImages, ...files]);
    };

    // Remove uploaded image
    const removeImage = (indexToRemove) => {
        setImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
    };

    // Analyze uploaded images
    const handleAnalyzeImages = async () => {
        setLoading(true);
        try {
            const response = await ApiService.updateInventory(images);
            setNewItems(response.items || []);
            showMessage(t('wardrobePage.messages.analyzeSuccess'), 'success');
        } catch (error) {
            console.error('Error analyzing images:', error);
            showMessage(t('wardrobePage.messages.analyzeError'), 'error');
        } finally {
            setLoading(false);
        }
    };

    // Save new items to wardrobe
    const handleSaveItems = async () => {
        setLoading(true);
        try {
            const response = await ApiService.saveUpdatedItems(newItems);
            setInventory(prevInventory => [...prevInventory, ...newItems]);
            setNewItems([]);
            setImages([]);
            showMessage(t('wardrobePage.messages.saveSuccess'), 'success');
        } catch (error) {
            console.error('Error saving items:', error);
            showMessage(t('wardrobePage.messages.saveError'), 'error');
        } finally {
            setLoading(false);
        }
    };

    // Fetch inventory on component mount
    useEffect(() => {
        const fetchInventory = async () => {
            try {
                const response = await ApiService.getInventory();
                setInventory(response || []);
            } catch (error) {
                console.error('Error fetching inventory:', error);
                showMessage(t('wardrobePage.messages.fetchError'), 'error');
            }
        };
        fetchInventory();
    }, []);

    return (
        <motion.div 
            className="wardrobe-page"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            {/* Navigation Tabs */}
            <div className="wardrobe-tabs">
                {WARDROBE_SECTIONS.map(section => (
                    <motion.button
                        key={section.id}
                        className={`tab-button ${activeSection === section.id ? 'active' : ''}`}
                        onClick={() => setActiveSection(section.id)}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <FontAwesomeIcon icon={section.icon} />
                        {section.label}
                    </motion.button>
                ))}
            </div>

            {/* Upload Section */}
            <AnimatePresence mode="wait">
                {activeSection === 'upload' && (
                    <motion.div 
                        key="upload-section"
                        variants={sectionVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        className="upload-section"
                    >
                        <h2>{t('wardrobePage.upload.title')}</h2>
                        <input 
                            type="file" 
                            ref={fileInputRef}
                            onChange={handleImageUpload} 
                            multiple 
                            accept="image/*" 
                            style={{ display: 'none' }}
                        />
                        <motion.div 
                            className="upload-area"
                            onClick={() => fileInputRef.current.click()}
                            whileHover={{ scale: 1.02 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <FontAwesomeIcon icon={faUpload} className="upload-icon" />
                            <p>{t('wardrobePage.upload.dropzone')}</p>
                        </motion.div>

                        {/* Image Preview */}
                        <div className="image-preview-grid">
                            {images.map((image, index) => (
                                <motion.div 
                                    key={index} 
                                    className="image-preview"
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0.8 }}
                                >
                                    <img 
                                        src={URL.createObjectURL(image)} 
                                        alt={`Uploaded item ${index + 1}`} 
                                    />
                                    <button 
                                        className="remove-image-btn"
                                        onClick={() => removeImage(index)}
                                    >
                                        ✕
                                    </button>
                                </motion.div>
                            ))}
                        </div>

                        {images.length > 0 && (
                            <motion.button
                                className="save-preferences-btn"
                                onClick={handleAnalyzeImages}
                                disabled={loading}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                {loading ? t('wardrobePage.upload.analyzing') : t('wardrobePage.upload.analyze')}
                            </motion.button>
                        )}

                        {newItems.length > 0 && (
                            <motion.button
                                className="save-preferences-btn"
                                onClick={handleSaveItems}
                                disabled={loading}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                {loading ? t('wardrobePage.upload.saving') : t('wardrobePage.upload.save')}
                            </motion.button>
                        )}
                    </motion.div>
                )}

                {/* Inventory Section */}
                {activeSection === 'inventory' && (
                    <motion.div 
                        key="inventory-section"
                        variants={sectionVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        className="inventory-section"
                    >
                        <h2>{t('wardrobePage.inventory.title')}</h2>
                        <div className="inventory-grid">
                            {inventory.map((item, index) => (
                                <motion.div 
                                    key={index} 
                                    className="inventory-item"
                                    whileHover={{ scale: 1.05 }}
                                    transition={{ type: "spring", stiffness: 300 }}
                                >
                                    <div className="item-details">
                                        <h3>{item.name}</h3>
                                        <p>{t('wardrobePage.inventory.itemDetails.type')}: {item.type}</p>
                                        <p>{t('wardrobePage.inventory.itemDetails.category')}: {item.description?.category}</p>
                                        <p>{t('wardrobePage.inventory.itemDetails.color')}: {item.description?.color}</p>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                        {inventory.length === 0 && (
                            <p>{t('wardrobePage.inventory.empty')}</p>
                        )}
                    </motion.div>
                )}

                {/* Categories Section */}
                {activeSection === 'categories' && (
                    <motion.div 
                        key="categories-section"
                        variants={sectionVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        className="categories-section"
                    >
                        <h2>{t('wardrobePage.categories.title')}</h2>
                        <div className="categories-grid">
                            {ITEM_CATEGORIES.map(category => (
                                <motion.div 
                                    key={category} 
                                    className="category-item"
                                    whileHover={{ scale: 1.05 }}
                                    transition={{ type: "spring", stiffness: 300 }}
                                >
                                    <h3>{category}</h3>
                                    <ul>
                                        {ITEM_TYPES[category].map(type => (
                                            <li key={type}>{type}</li>
                                        ))}
                                    </ul>
                                </motion.div>
                            ))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
};

export default WardrobePage;
