// File: src/context/EventContext.js
import React, { createContext, useState } from 'react';

// Create the EventContext
const EventContext = createContext();

// Create the EventProvider component
export const EventProvider = ({ children }) => {
    const [event, setEvent] = useState('Casual'); // Initialize event state

    return (
        <EventContext.Provider value={{ event, setEvent }}>
            {children}
        </EventContext.Provider>
    );
};

export default EventContext;
