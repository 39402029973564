// File: src/main_pages/HelpMeChoosePage.js
import React, { useState, useRef } from 'react';
import ApiService from '../services/apiService';
import CommonPage from "./CommonPage";
import MultiStepHelpMeChoose from "./multiSteps/MultiStepHelpMeChoose";
import { useTranslation } from 'react-i18next';

const HelpMeChoosePage = () => {

    const { t } = useTranslation();

    const promptOptions = [
        { value: 'judge_real', label: 'Real Judge' },
    ];

    return (
        <div>
            <CommonPage
                promptOptions={promptOptions}
                showPromptOptions={false}
                fetchRecommendations={true}
                pageDescription={t('pageDescriptions.helpMeChoose')}
                uploadMethod={ApiService.compareOutfits}
                CustomControls={<MultiStepHelpMeChoose />} // Pass MultiStepMirror as customControls
            />
        </div>
    );
};

export default HelpMeChoosePage;
