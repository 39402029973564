import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { loadGraphModel } from '@tensorflow/tfjs';
import * as tf from '@tensorflow/tfjs';
// eslint-disable-next-line
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSyncAlt,
    faChevronLeft,
    faChevronRight,
    faPowerOff
} from '@fortawesome/free-solid-svg-icons';
import '../styles/components/VideoContainer.css';
import ImagesPreview from './ImagesPreview';
import useAutoDismissMessage from '../hooks/useAutoDismissMessage';
import Message from './Message';
import FloatingControls from './FloatingControls';
import ShopSessionManager from '../services/ShopSessionManager';
import { useTranslation } from 'react-i18next';

// Ensure TensorFlow is loaded before component initialization
const loadTensorFlow = async () => {
    try {
        await tf.ready();
        return true;
    } catch (error) {
        console.error('Failed to load TensorFlow.js', error);
        return false;
    }
};

const LandingVideoContainer = forwardRef(({
   uploadMethod,
   actionText,
   showImagePreview = true,
   showEventSelection = true,
   showCallToActions = false,
   CustomControls, 
   autoCapture = true,
   autoCaptureProbability = 0.7
}, ref) => {
    const { t } = useTranslation();
    const [facingMode, setFacingMode] = useState('user');
    const [stream, setStream] = useState(null);
    const [isCameraActive, setIsCameraActive] = useState(() => {
        return JSON.parse(localStorage.getItem('isCameraActive')) ?? true;
    });
    const [images, setImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [countdown, setCountdown] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [detectionModel, setDetectionModel] = useState(null);
    const [tensorFlowReady, setTensorFlowReady] = useState(false);
    const [personDetected, setPersonDetected] = useState(false);
    const [personalGreeting, setPersonalGreeting] = useState(null);
    const [sessionKey, setSessionKey] = useState(null);
    const sessionManager = new ShopSessionManager();
    const countdownIntervalRef = useRef(null);
    const canvasRef = useRef(null);
    const videoRef = useRef(null);
    const detectionCanvasRef = useRef(null);
    const messageRef = useRef(null);
    const selfieCaptureDelay = 7000;
    const { message, type, showMessage, clearMessage } = useAutoDismissMessage();


    // Load TensorFlow and COCO-SSD model on component mount
    useEffect(() => {
        const initializeTensorFlow = async () => {
            try {
                // First ensure TensorFlow is ready
                const tfLoaded = await loadTensorFlow();
                if (!tfLoaded) {
                    showMessage(t('messages.common.loadTensorFlowError'), 'error');
                    return;
                }
                setTensorFlowReady(true);

                // Then load COCO-SSD model
                const model = await cocoSsd.load();
                setDetectionModel(model);
            } catch (error) {
                console.error('Failed to load detection model:', error);
                showMessage(t('messages.common.loadPersonDetectionError'), 'error');
            }
        };

        initializeTensorFlow();
    }, []);

    // Person detection and auto-capture logic
    useEffect(() => {
        if (!autoCapture || !detectionModel || !tensorFlowReady || images.length > 0 || !videoRef.current) return;

        let isProcessing = false; // Flag to track processing state

        const detectPerson = async () => {
            if (isProcessing) return; // Prevent concurrent execution
            isProcessing = true; // Set processing flag

            try {
                const video = videoRef.current;
                const detectionCanvas = detectionCanvasRef.current;
                
                if (!video || video.readyState !== video.HAVE_ENOUGH_DATA) return;

                // Prepare canvas for detection
                detectionCanvas.width = video.videoWidth;
                detectionCanvas.height = video.videoHeight;
                const context = detectionCanvas.getContext('2d');
                context.drawImage(video, 0, 0, detectionCanvas.width, detectionCanvas.height);

                // Detect objects
                const predictions = await detectionModel.detect(detectionCanvas);

                // Check for person with high confidence
                const currentPersonDetected = await predictions.some(
                    prediction => 
                        prediction.class === 'person' && 
                        prediction.score >= autoCaptureProbability
                );

                setPersonDetected(currentPersonDetected);
                
                if (sessionManager && currentPersonDetected) {
                    console.log('Session Key:', sessionManager.sessionKey);
                    console.log('Person Detected:', currentPersonDetected);

                    // Show welcome message
                    if (currentPersonDetected) {
                        sessionManager.updateSessionStatus(currentPersonDetected);
                        setSessionKey(sessionManager.sessionKey);
                        setPersonalGreeting(`Hello! Your session key is ${sessionManager.sessionKey}`);
                    }
                    
                    try {
                        const canvas = canvasRef.current;
                        const video = videoRef.current;
                        
                        if (!canvas || !video) {
                            throw new Error('Canvas or video reference not available');
                        }

                        const context = canvas.getContext('2d');
                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;
                        context.drawImage(video, 0, 0, canvas.width, canvas.height);
                        
                        // Convert toBlob to Promise for better async handling
                        const blob = await new Promise((resolve, reject) => {
                            canvas.toBlob((blob) => {
                                if (blob) {
                                    resolve(blob);
                                } else {
                                    reject(new Error('Failed to create image blob'));
                                }
                            }, 'image/jpeg');
                        });

                        setImages([blob]);
                        setShowConfirmation(false); // Show ImageConfirmation after image is captured
                        console.log('Image captured successfully');
                        await handleSubmit(blob); // Directly pass the blob to handleSubmit
                    } catch (error) {
                        console.error('Error capturing image:', error);
                        showMessage(t('messages.common.captureError', { error: error.message }), 'error');
                    }
                }
                
            } catch (error) {
                console.error('Person detection error:', error);
            } finally {
                isProcessing = false; // Reset processing flag
            }
        };

        // Set up detection interval
        const detectionInterval = setInterval(detectPerson, 1000);

        // Cleanup
        return () => {
            clearInterval(detectionInterval);
        };
    }, [
        detectionModel, 
        tensorFlowReady, 
        autoCapture, 
        images.length, 
        autoCaptureProbability, 
        personalGreeting,
    ]);

    const startStream = () => {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        navigator.mediaDevices.getUserMedia({ video: { facingMode } })
            .then(stream => {
                videoRef.current.srcObject = stream;
                videoRef.current.onloadedmetadata = () => {
                    videoRef.current.play().catch(error => {
                        console.error('Error trying to play video:', error);
                    });
                };
                setStream(stream);
            })
            .catch(err => {
                console.error('Error accessing camera: ', err);
                alert('Error accessing camera: ' + err.message);
            });
    };

    const toggleCamera = () => {
        if (isCameraActive) {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
            setStream(null);
        } else {
            startStream();
        }
        setIsCameraActive(prevState => {
            const newState = !prevState;
            localStorage.setItem('isCameraActive', JSON.stringify(newState)); // Persist to localStorage
            return newState;
        });
    };

    useEffect(() => {
        if (isCameraActive && videoRef && videoRef.current) {
            startStream();
        }
        return () => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
            if (countdownIntervalRef.current) {
                clearInterval(countdownIntervalRef.current);
            }
        };
    }, [facingMode, isCameraActive]);

    const handleUpload = (event) => {
        const files = Array.from(event.target.files);
        if (files) {
            setImages((prevImages) => [...prevImages, ...files]);
            setCurrentImageIndex(0);
            setShowConfirmation(true); // Show ImageConfirmation when image is uploaded
        }
    };

    const handleCaptureClick = (captureDelay) => {
        if (canvasRef.current && videoRef.current) {
            if (facingMode === 'user') {
                if (countdown === null && !countdownIntervalRef.current && images.length === 0) {
                    setCountdown(captureDelay / 1000);
                    startCountdown();
                }
            } else {
                captureImage();
            }
        }
    };

    const startCountdown = () => {
        countdownIntervalRef.current = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    clearInterval(countdownIntervalRef.current);
                    countdownIntervalRef.current = null;
                    captureImage();
                    return null;
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };

    const captureImage = () => {
        const canvas = canvasRef.current;
        const video = videoRef.current;
        const context = canvas.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(async (blob) => {
            if (blob) {
                setImages([blob]);
                setShowConfirmation(true); // Show ImageConfirmation after image is captured
                await handleSubmit(blob); // Directly pass the blob to handleSubmit
            }
        }, 'image/jpeg');
    };

    const handleSubmit = async (imageBlob) => {
        if (!imageBlob) {
            console.error('Please upload at least 1 photo');
            showMessage(t('messages.common.uploadMinPhotos'), 'error');
            return;
        }
        setIsSubmitting(true);
        try {
            await uploadMethod([imageBlob]); // Pass the blob as an array
            showMessage(t('messages.common.uploadSuccess'), 'success');
            setShowConfirmation(false);
        } catch (error) {
            console.error('Error uploading photos:', error);
            showMessage(error.response?.data?.message || t('messages.common.unexpectedError'), 'error');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSwitchCamera = () => {
        setFacingMode(facingMode === 'user' ? 'environment' : 'user');
        if (countdownIntervalRef.current) {
            clearInterval(countdownIntervalRef.current);
            countdownIntervalRef.current = null;
            setCountdown(null);
        }
    };

    const handleImageRemove = (index) => {
        setImages((prevImages) => {
            const updatedImages = prevImages.filter((_, i) => i !== index);
            if (updatedImages.length === 0) {
                startStream();
            }
            return updatedImages;
        });
        setCurrentImageIndex((prevIndex) => Math.max(0, prevIndex - 1));
    };

    const clearImages = () => {
        setImages([]);
        setCurrentImageIndex(0);
        setShowConfirmation(false); // Reset to MultiStepMirror
        startStream();
    };

    useImperativeHandle(ref, () => ({
        handleCaptureClick,
    }));

    return (
        <div className="w-100 video-container-wrapper">
            <div className="video-container mb-4 border rounded position-relative">
                {/* Person Detection Indicator */}
                <div 
                    style={{
                        position: 'absolute', 
                        top: '10px', 
                        left: '10px', 
                        width: '15px', 
                        height: '15px', 
                        borderRadius: '50%', 
                        backgroundColor: personDetected ? 'green' : 'red', 
                        zIndex: 10,
                        boxShadow: '0 0 5px rgba(0,0,0,0.5)'
                    }} 
                    title={personDetected ? 'Person Detected' : 'No Person Detected'}
                />

                {/* Session Key Indicator */}
                <div className="session-key" style={{
                    position: 'absolute',
                    top: '10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    color: 'white',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    zIndex: 10
                }}>
                    Session: {sessionKey}
                </div>

                {message && (
                    <div className="floating-event-selection">
                        <Message message={message} type={type} ref={messageRef} />
                    </div>
                )}
                {/* Camera Toggle Button */}
                <button
                    onClick={toggleCamera}
                    className="camera-toggle-button"
                    title={isCameraActive ? 'Turn Off Camera' : 'Turn On Camera'}
                >
                    <FontAwesomeIcon icon={faPowerOff} />
                </button>
                {/* Camera switch button */}
                <button
                    onClick={handleSwitchCamera}
                    className="btn btn-secondary camera-switch-button"
                    title="Switch Camera"
                >
                    <FontAwesomeIcon icon={faSyncAlt} />
                </button>

                {images.length === 0 && (
                    <video
                        ref={videoRef}
                        autoPlay
                        playsInline
                        muted
                        preload="auto"
                        className="rounded video-element"
                    ></video>
                )}
                {images.length > 0 && (
                    <div className="image-slider">
                        <button onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length)} className="slider-button left">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <img
                            src={images[currentImageIndex] instanceof Blob ? URL.createObjectURL(images[currentImageIndex]) : images[currentImageIndex]}
                            alt="Captured"
                            className="rounded captured-image"
                        />
                        <button onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)} className="slider-button right">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                )}
                <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
                <canvas ref={detectionCanvasRef} style={{ display: 'none' }}></canvas>
                {countdown !== null && (
                    <div className="countdown-overlay">
                        <h1>{countdown}</h1>
                    </div>
                )}
                {isSubmitting && (
                    <div className="scan-overlay">
                        <div className="scan-line"></div>
                    </div>
                )}
                {personalGreeting && (
                    <div className="personal-greeting">
                        <h2>{personalGreeting}</h2>
                    </div>
                )}
                {/* Use the new FloatingControls component here */}
                <FloatingControls
                    images={images}
                    isSubmitting={isSubmitting}
                    showConfirmation={showConfirmation}
                    countdown={countdown}
                    showCallToActions={showCallToActions}
                    onCapture={() => handleCaptureClick(selfieCaptureDelay)}
                    onUpload={handleUpload}
                    onSubmit={handleSubmit}
                    onClear={clearImages}
                    onSwitchCamera={handleSwitchCamera}
                    CustomControls={CustomControls} // Pass custom controls here
                />
            </div>
            {showImagePreview && !isSubmitting && (
                <ImagesPreview images={images} clearImages={clearImages} handleImageRemove={handleImageRemove} />
            )}
        </div>
    );
});

export default LandingVideoContainer;
