import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import EventContext from '../../context/EventContext';
import config from '../../config';
import '../../styles/pages/multiStepMirror.css';

const MultiStepMirror = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { event, setEvent } = useContext(EventContext);

    const [selectedStyle, setSelectedStyle] = useState('improving');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        // Set default event value if it's not already set
        if (!event) {
            setEvent('Casual'); 
        }
    }, [event, setEvent]);

    const styles = [
        { value: 'flattering', label: t('mirror.styles.flattering') },
        { value: 'honest', label: t('mirror.styles.honest') },
        { value: 'improving', label: t('mirror.styles.improving') },
        { value: 'professional', label: t('mirror.styles.professional') },
    ];

    const handleProceed = () => {
        if (!selectedStyle || !event) {
            alert(t('mirror.selectBoth')); // Alert if both selections are not made
            return;
        }

        setIsSubmitting(true);
        navigate('/mirror', {
            state: { 
                autoCapture: true, 
                event: event, 
                style: selectedStyle 
            }
        });
    };

    return (
        <div className="multi-step-mirror-container">
            <form>
                <div className="form-group">
                    <select 
                        id="styleSelect" 
                        value={selectedStyle} 
                        onChange={(e) => setSelectedStyle(e.target.value)}
                        disabled={isSubmitting}
                    >
                        <option value="">{t('mirror.selectStyle')}</option>
                        {styles.map(style => (
                            <option key={style.value} value={style.value}>
                                {style.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <select 
                        id="eventSelect" 
                        value={event} 
                        onChange={(e) => setEvent(e.target.value)}
                        disabled={isSubmitting}
                    >
                        <option value="">{t('events.selectEvent')}</option>
                        {config.events.map(eventOption => (
                            <option key={eventOption} value={eventOption}>
                                {t(`events.predefinedEvents.${eventOption.replace(/\s+/g, '')}`, eventOption)}
                            </option>
                        ))}
                    </select>
                </div>

                <button 
                    type="button"
                    onClick={handleProceed} 
                    className="btn btn-gradient-pink"
                    disabled={isSubmitting}
                >
                    {t('actions.GO')}
                </button>
            </form>
        </div>
    );
};

export default MultiStepMirror;
