import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faEdit, faUser, faTshirt, 
    faRuler, faShoppingBag, faCog, 
    faSignOutAlt, faCamera, faClothesHanger, faUserFriends 
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import ApiService from '../services/apiService';
import { logout } from '../services/logoutService';
import '../styles/pages/myProfile.css';

const ProfileSection = ({ icon, title, description, onClick }) => {
    const { t } = useTranslation();
    return (
        <motion.div 
            className="profile-section"
            onClick={onClick}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
        >
            <div className="profile-section-icon">
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className="profile-section-content">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </motion.div>
    );
};

const MyProfilePage = () => {
    const { t } = useTranslation();
    const [profileImage, setProfileImage] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState('');
    const [reloadKey, setReloadKey] = useState(Date.now());
    const [userEmail, setUserEmail] = useState('');
    const fileInputRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch user email from cookie
        const email = Cookies.get('user_email') || 'User';
        setUserEmail(email);

        const fetchImage = async () => {
            try {
                const blob = await ApiService.fetchProfileImage();
                const url = URL.createObjectURL(blob);
                setProfileImage(url);
            } catch (err) {
                console.error('Failed to fetch profile image:', err);
            }
        };

        fetchImage();

        return () => {
            if (profileImage) {
                URL.revokeObjectURL(profileImage);
            }
        };
    }, [reloadKey]);

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            if (file.size > 5 * 1024 * 1024) {
                alert(t('myProfile.imageUpload.sizeError'));
                return;
            }
            setIsUploading(true);
            setError('');
            try {
                const response = ApiService.uploadProfileImage(file);
                // Immediately update the profile image
                const url = URL.createObjectURL(file);
                setProfileImage(url);
                const newReloadKey = Date.now();
                await response;
                setReloadKey(newReloadKey);
            } catch (err) {
                setError(t('myProfile.imageUpload.uploadError'));
                console.error('Image upload error:', err);
            } finally {
                setIsUploading(false);
            }
        } else {
            alert(t('myProfile.imageUpload.typeError'));
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const profileSections = [
        {
            icon: faTshirt,
            title: t('myProfile.sections.outfitHistory.title'),
            description: t('myProfile.sections.outfitHistory.description'),
            onClick: () => navigate('/outfit-history')
        },
        {
            icon: faTshirt,
            title: t('myProfile.sections.wardrobe.title'),
            description: t('myProfile.sections.wardrobe.description'),
            onClick: () => navigate('/inventory')
        },
        {
            icon: faRuler,
            title: t('myProfile.sections.measurements.title'),
            description: t('myProfile.sections.measurements.description'),
            onClick: () => navigate('/measurements')
        },
        {
            icon: faShoppingBag,
            title: t('myProfile.sections.shoppingHistory.title'),
            description: t('myProfile.sections.shoppingHistory.description'),
            onClick: () => navigate('/shopping-history')
        },
        {
            icon: faUserFriends,
            title: t('myProfile.sections.socialNetwork.title'),
            description: t('myProfile.sections.socialNetwork.description'),
            onClick: () => navigate('/social-network')
        },
        {
            icon: faCog,
            title: t('myProfile.sections.accountSettings.title'),
            description: t('myProfile.sections.accountSettings.description'),
            onClick: () => navigate('/account-settings')
        }
    ];

    return (
        <motion.div 
            className="my-profile-page"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <div className="profile-header">
                <div className="profile-image-container">
                    <motion.div 
                        className="profile-image-wrapper"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        {profileImage ? (
                            <img
                                src={profileImage}
                                alt="User Profile"
                                className="profile-image"
                            />
                        ) : (
                            <img
                                src="/images/default-profile.png"
                                alt="Default User Profile"
                                className="profile-image"
                            />
                        )}
                        <motion.button
                            className="edit-profile-image"
                            onClick={triggerFileInput}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <FontAwesomeIcon icon={faCamera} />
                        </motion.button>
                    </motion.div>
                    <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                    />
                </div>
                <h1 className="profile-name">{t('myProfile.title')}</h1>
                <p className="profile-email">{userEmail}</p>
            </div>

            <div className="profile-sections">
                <AnimatePresence>
                    {profileSections.map((section, index) => (
                        <ProfileSection 
                            key={section.title}
                            {...section}
                        />
                    ))}
                </AnimatePresence>
            </div>

            <motion.button 
                className="logout-button"
                onClick={logout}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
            >
                <FontAwesomeIcon icon={faSignOutAlt} />
                {t('myProfile.logout')}
            </motion.button>

            {isUploading && (
                <motion.div 
                    className="upload-status"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                >
                    {t('myProfile.uploadStatus')}
                </motion.div>
            )}
            {error && (
                <motion.div 
                    className="error-message"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                >
                    {error}
                </motion.div>
            )}
        </motion.div>
    );
};

export default MyProfilePage;
