// File: src/components/FloatingControls.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSyncAlt,
    faUpload,
    faCamera,
    faPaperPlane,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import ImageConfirmation from './ImageConfirmation';

const FloatingControls = ({
                              images,
                              isSubmitting,
                              showConfirmation,
                              countdown,
                              showCallToActions,
                              onCapture,
                              onUpload,
                              onSubmit,
                              onClear,
                              onSwitchCamera,
                              CustomControls, // New prop for custom controls
                          }) => {
    return (
        <div className="floating-controls">
            <div className="floating-buttons">
                {/* <button onClick={onSwitchCamera} className="btn btn-secondary" disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faSyncAlt} />
                </button> */}
                {images.length === 0 ? (
                    <div></div>
                    // <button onClick={onCapture} className="btn btn-primary" disabled={countdown !== null || isSubmitting}>
                    //     <FontAwesomeIcon icon={faCamera} />
                    // </button>
                ) : (
                    <button onClick={onClear} className="btn btn-secondary" disabled={isSubmitting}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                )}
                <label className="btn btn-secondary">
                    <FontAwesomeIcon icon={faUpload} />
                    <input type="file" accept="image/*" multiple onChange={onUpload} style={{ display: 'none' }} />
                </label>
                {/* <button onClick={onSubmit} className="btn btn-primary" disabled={images.length === 0 || isSubmitting}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                </button> */}
            </div>
            <div className="cta-container">
                {showCallToActions && !showConfirmation && !countdown && !isSubmitting && (
                    React.cloneElement(CustomControls, { isSubmitting, countdown, showConfirmation })
                )}
                {showConfirmation && !countdown && !isSubmitting && (
                    <ImageConfirmation
                        onSubmit={onSubmit}
                        onRetake={onClear}
                    />
                )}
            </div>
        </div>
    );
};

export default FloatingControls;
