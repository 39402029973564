// File: src/App.js
import React, { Suspense, useEffect } from 'react';
import { initialize } from 'react-native-clarity';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';

import ShopPage from './main_pages/ShopPage';
import MirrorPage from './main_pages/MirrorPage';
import LoginPage from './pages/LoginPage';
import StyleMeterPage from './main_pages/StyleMeterPage';
import HelpMeChoosePage from './main_pages/HelpMeChoosePage';
import InventoryPage from './pages/InventoryPage';
import Layout from './components/Layout';
import AddItemPage from './pages/AddItemPage';
import MyProfilePage from './pages/MyProfilePage';
import MeasurementsPage from './pages/MeasurementsPage';
import ResultPage from './pages/ResultPage';
import ProtectedRoute from './components/ProtectedRoute';
import config from './config';
import WardrobePage from './pages/WardrobePage';
import MirrorLandingPage from './real_mirror_pages/MirrorLandingPage';
import WelcomeAnimation from './pages/WelcomeAnimation'; // Import WelcomeAnimation component

// Import providers
import { LanguageProvider } from './context/LanguageContext';
import { EventProvider } from './context/EventContext';
import MyWardrobePage from "./main_pages/MyWardrobePage";
import HomePage from "./main_pages/HomePage";
import OutfitHistoryPage from './pages/OutfitHistoryPage';
import { SessionProvider } from './context/SessionContext';

// Loading component for suspense fallback
const Loading = () => (
    <div className="loading">
        <p>Loading...</p>
    </div>
);

const App = () => {
    useEffect(() => {
        try {
            // Initialize Microsoft Clarity with your Project ID
            initialize(config.clarityProjectId);
            console.log('Clarity initialized successfully');
        } catch (error) {
            console.error('Error initializing Clarity:', error);
        }
    }, []);

    // Set initial HTML language attribute
    useEffect(() => {
        const savedLanguage = localStorage.getItem('i18nextLng');
        if (savedLanguage) {
            document.documentElement.lang = savedLanguage;
            document.documentElement.dir = savedLanguage === 'ar' ? 'rtl' : 'ltr';
        }
    }, []);

    return (
        <SessionProvider>
            <I18nextProvider i18n={i18n}>
                <Suspense fallback={<Loading />}>
                    <LanguageProvider>
                        <EventProvider>
                            <Router>
                                <Routes>
                                    {/* Public Routes */}
                                    <Route path="/login" element={<LoginPage />} />

                                    {/* Protected Routes Wrapped with Layout */}
                                    <Route element={<Layout />}>
                                        {/* Redirect root to /home */}
                                        <Route index element={<Navigate to="/home" replace />} />

                                        {/* Protected Routes */}
                                        <Route element={<ProtectedRoute />}>
                                            <Route path="/shop-welcome" element={<MirrorLandingPage />} />
                                            <Route path="/welcome-animation/:taskId" element={<WelcomeAnimation />} />
                                            <Route path="/result/:taskId" element={<ResultPage />} />
                                            <Route path="home" element={<HomePage />} />
                                            <Route path="mirror" element={<MirrorPage />} />
                                            <Route path="shop-dresser" element={<ShopPage />} />
                                            <Route path="home-dresser" element={<MyWardrobePage />} />
                                            <Route path="style-meter" element={<StyleMeterPage />} />
                                            <Route path="outfit-chooser" element={<HelpMeChoosePage />} />
                                            <Route path="inventory" element={<WardrobePage />} />
                                            <Route path="add-item" element={<AddItemPage />} />
                                            <Route path="profile" element={<MyProfilePage />} />
                                            <Route path="measurements" element={<MeasurementsPage />} />
                                            <Route path="wardrobe" element={<WardrobePage />} />
                                            <Route path="outfit-history" element={<OutfitHistoryPage />} />
                                        </Route>
                                    </Route>

                                    {/* Fallback Route */}
                                    <Route path="*" element={<Navigate to="/home" replace />} />
                                </Routes>
                            </Router>
                        </EventProvider>
                    </LanguageProvider>
                </Suspense>
            </I18nextProvider>
        </SessionProvider>
    );
};

export default App;
