import React, { createContext, useContext, useState } from 'react';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
    const [sessionKey, setSessionKey] = useState(null);

    return (
        <SessionContext.Provider value={{ sessionKey, setSessionKey }}>
            {children}
        </SessionContext.Provider>
    );
};

export const useSession = () => useContext(SessionContext);
