import React from 'react';
import { RWebShare } from 'react-web-share';
import config from "../config";
import '../styles/components/ShareButton.css';

const ShareButton = ({ taskId, title, text, image }) => {
    const url = `${config.frontend_hostname}/result/${taskId}`;
    return (
        <div className="share-button-container mt-4">
            <RWebShare
                data={{
                    text: text,
                    url: url,
                    title: title,
                    image: image,
                }}
                onClick={() => console.log("Shared data:", { url, title, text, image })}
            >
                <button className="share-button">
                    <span className="share-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 5.12548 15.0077 5.24917 15.0227 5.37061L8.08261 9.19071C7.54305 8.46669 6.78899 8 5.93426 8C4.31055 8 3 9.34315 3 11C3 12.6569 4.31055 14 5.93426 14C6.78899 14 7.54305 13.5333 8.08261 12.8093L15.0227 16.6294C15.0077 16.7508 15 16.8745 15 17C15 18.6569 16.3431 20 18 20C19.6569 20 21 18.6569 21 17C21 15.3431 19.6569 14 18 14C17.1453 14 16.3912 14.4667 15.8517 15.1907L8.91165 11.3706C8.92664 11.2492 8.93426 11.1255 8.93426 11C8.93426 10.8745 8.92664 10.7508 8.91165 10.6294L15.8517 6.80929C16.3912 7.53331 17.1453 8 18 8Z" fill="currentColor"/>
                        </svg>
                    </span>
                    <span className="share-text">Share</span>
                </button>
            </RWebShare>
        </div>
    );
};

export default ShareButton;
