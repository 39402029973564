import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import EventContext from '../../context/EventContext';
import config from '../../config';
import '../../styles/pages/multiStepMirror.css';

const MultiStepShopDresser = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { event, setEvent } = useContext(EventContext);

    const [selectedCategory, setSelectedCategory] = useState('all');
    const [selectedStore, setSelectedStore] = useState('zara');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        // Set default event value if it's not already set
        if (!event) {
            setEvent('Casual');
        }
    }, [event, setEvent]);

    const categories = ['all', 'top', 'bottom', 'accessories', 'shoes'];
    const stores = ['zara', 'nike', 'gucci'];

    const handleProceed = () => {
        if (!event || !selectedCategory || !selectedStore) {
            alert(t('shopDresser.selectAll')); // Alert if all selections are not made
            return;
        }

        setIsSubmitting(true);
        navigate('/shop-dresser', {
            state: { 
                autoCapture: true, 
                event: event,
                category: selectedCategory,
                style: selectedStore
            }
        });
    };

    return (
        <div className="multi-step-mirror-container">
            <form>
                <div className="form-group">
                    <select 
                        id="eventSelect" 
                        value={event} 
                        onChange={(e) => setEvent(e.target.value)}
                        disabled={isSubmitting}
                    >
                        <option value="">{t('events.selectEvent')}</option>
                        {config.events.map(eventOption => (
                            <option key={eventOption} value={eventOption}>
                                {t(`events.predefinedEvents.${eventOption.replace(/\s+/g, '')}`, eventOption)}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <select 
                        id="categorySelect" 
                        value={selectedCategory} 
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        disabled={isSubmitting}
                    >
                        <option value="">{t('events.selectCategory')}</option>
                        {categories.map(category => (
                            <option key={category} value={category}>
                                {t(`categories.${category}`)}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <select 
                        id="storeSelect" 
                        value={selectedStore} 
                        onChange={(e) => setSelectedStore(e.target.value)}
                        disabled={isSubmitting}
                    >
                        <option value="">{t('shopDresser.chooseStore')}</option>
                        {stores.map(store => (
                            <option key={store} value={store}>
                                {store.charAt(0).toUpperCase() + store.slice(1)}
                            </option>
                        ))}
                    </select>
                </div>

                <button 
                    type="button"
                    onClick={handleProceed} 
                    className="btn btn-gradient-pink"
                    disabled={isSubmitting}
                >
                    {t('actions.GO')}
                </button>
            </form>
        </div>
    );
};

export default MultiStepShopDresser;
