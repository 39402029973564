import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import EventContext from '../../context/EventContext';
import '../../styles/pages/multiStepMirror.css';

const MultiStepStyleMeter = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { event, setEvent } = useContext(EventContext);

    const [selectedStyle, setSelectedStyle] = useState('style_meter_whole_outfit');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const styles = [
        'style_meter_whole_outfit',
        'style_meter_colors',
        'style_meter_accessories',
        'style_meter_trends',
        'style_meter_fit_and_tailoring'
    ];

    const events = [
        'Casual', 'Formal', 'Sports', 'Party', 'Wedding',
        'Beach', 'Night Out', 'Work', 'Holiday', 'Date Night'
    ];

    const handleProceed = () => {
        if (!event || !selectedStyle) {
            alert(t('styleMeter.selectBoth')); // Alert if both selections are not made
            return;
        }

        setIsSubmitting(true);
        navigate('/style-meter', {
            state: { 
                autoCapture: true, 
                event: event,
                style: selectedStyle
            }
        });
    };

    return (
        <div className="multi-step-mirror-container">
            <form>
                <div className="form-group">
                    <select 
                        id="eventSelect" 
                        value={event} 
                        onChange={(e) => setEvent(e.target.value)}
                        disabled={isSubmitting}
                    >
                        <option value="">{t('events.selectEvent')}</option>
                        {events.map(eventOption => (
                            <option key={eventOption} value={eventOption}>
                                {t(`events.predefinedEvents.${eventOption.replace(/\s+/g, '')}`, eventOption)}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <select 
                        id="styleSelect" 
                        value={selectedStyle} 
                        onChange={(e) => setSelectedStyle(e.target.value)}
                        disabled={isSubmitting}
                    >
                        <option value="">{t('styleMeter.chooseStyle')}</option>
                        {styles.map(style => (
                            <option key={style} value={style}>
                                {t(`styleMeter.styles.${style}`)}
                            </option>
                        ))}
                    </select>
                </div>

                <button 
                    type="button"
                    onClick={handleProceed} 
                    className="btn btn-gradient-pink"
                    disabled={isSubmitting}
                >
                    {t('actions.GO')}
                </button>
            </form>
        </div>
    );
};

export default MultiStepStyleMeter;
