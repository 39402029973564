import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

export const SUPPORTED_LANGUAGES = ['en', 'es', 'fr', 'he', 'br', 'it'];

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: SUPPORTED_LANGUAGES,
    
    // Language detection configuration
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },

    // Backend configuration for loading translations
    backend: {
      loadPath: '/locales/{{lng}}.json',
      queryStringParams: {
        v: Date.now() // Add cache-busting
      }
    },

    // Interpolation configuration
    interpolation: {
      escapeValue: false,
    },

    // React configuration
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged',
      bindI18nStore: 'added removed',
    },
  });

// Force initial language from localStorage if available
const savedLanguage = localStorage.getItem('i18nextLng');
if (savedLanguage && SUPPORTED_LANGUAGES.includes(savedLanguage)) {
  i18n.changeLanguage(savedLanguage);
}

export default i18n;
