// src/pages/ResultPage.js
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faChevronLeft, 
    faChevronRight, 
    faShare, 
    faVolumeUp,
    faMagicWandSparkles,
    faShoppingCart,
    faStar
} from '@fortawesome/free-solid-svg-icons';

import ShareButton from '../components/ShareButton';
import ApiService from '../services/apiService';
import config from '../config';
import TopMenu from '../components/TopMenu';
import AudioPlayer from '../components/AudioPlayer';
import Message from '../components/Message';
import useAutoDismissMessage from '../hooks/useAutoDismissMessage';
import Recommendations, { loadRecommendations } from '../components/Recommendations';
import { useTranslation } from 'react-i18next';
import { motion as m } from 'framer-motion';
import { decode } from 'html-entities';

import '../styles/pages/resultPage.css';

const formatPrice = (price, currency = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency
  }).format(price);
};

const QuickViewModal = ({ item, onClose }) => {
  if (!item) return null;

  // Construct Amazon Add to Cart URL with affiliate tag
  const amazonAddToCartUrl = item.link;

  return (
    <AnimatePresence>
      <m.div 
        className="quick-view-modal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <m.div 
          className="quick-view-content"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
        >
          <button className="close-btn" onClick={onClose}>&times;</button>
          <div className="quick-view-grid">
            <div className="quick-view-images">
              {item.images.map((image, index) => (
                <img key={index} src={image} alt={`${decode(item.name)} - View ${index + 1}`} />
              ))}
            </div>
            <div className="quick-view-details">
              <h2>{decode(item.name)}</h2>
              
              <div className="price-rating-container">
                <div className="price-container">
                  <span className="current-price">{formatPrice(item.price, item.currency)}</span>
                  {item.original_price > item.price && (
                    <span className="original-price">{formatPrice(item.original_price, item.currency)}</span>
                  )}
                </div>
                {item.star_rating && (
                  <div className="rating-container">
                    <FontAwesomeIcon icon={faStar} className="star-icon" />
                    <span>{item.star_rating.toFixed(1)}</span>
                    {item.num_ratings && (
                      <span className="num-ratings">({item.num_ratings.toLocaleString()} ratings)</span>
                    )}
                  </div>
                )}
              </div>

              {item.description && (
                <div className="description">
                  <h3>Description</h3>
                  <p>{decode(item.description)}</p>
                </div>
              )}

              {item.details && (
                <div className="details">
                  <h3>Details</h3>
                  <p>{decode(item.details)}</p>
                </div>
              )}

              <div className="product-meta">
                {item.category && <div className="meta-item"><strong>Category:</strong> {item.category}</div>}
                {item.color && <div className="meta-item"><strong>Color:</strong> {item.color}</div>}
                {item.size && <div className="meta-item"><strong>Size:</strong> {item.size}</div>}
                {item.pattern && <div className="meta-item"><strong>Pattern:</strong> {item.pattern}</div>}
              </div>

              <div className="delivery-badges">
                {item.is_prime && <span className="badge prime">Prime Delivery</span>}
                {item.is_best_seller && <span className="badge bestseller">Best Seller</span>}
                {item.is_amazon_choice && <span className="badge amazon-choice">Amazon's Choice</span>}
                {item.climate_pledge_friendly && <span className="badge eco">Climate Pledge Friendly</span>}
              </div>

              <div className="action-buttons">
                <m.a 
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="buy-now-btn"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Buy Now
                </m.a>
                <m.a
                  href={amazonAddToCartUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="add-to-cart-btn"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FontAwesomeIcon icon={faShoppingCart} /> Add to Cart
                </m.a>
              </div>
            </div>
          </div>
        </m.div>
      </m.div>
    </AnimatePresence>
  );
};

const ResultPage = () => {
    const { taskId } = useParams();
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isTextExpanded, setIsTextExpanded] = useState(false);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [recommendations, setRecommendations] = useState([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const { t } = useTranslation();
    const { message, type, showMessage } = useAutoDismissMessage();

    const retryWithBackoff = async (fn, maxRetries = 3, initialDelay = 1000) => {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                return await fn();
            } catch (error) {
                if (error.response?.status === 400 && retries < maxRetries - 1) {
                    retries++;
                    const delay = initialDelay * Math.pow(2, retries - 1);
                    await new Promise(resolve => setTimeout(resolve, delay));
                    continue;
                }
                throw error;
            }
        }
    };

    useEffect(() => {
        const fetchResult = async () => {
            try {
                setLoading(true);
                const resultData = await retryWithBackoff(
                    () => ApiService.fetchResult(taskId)
                );
                setResult(resultData);
            } catch (error) {
                console.error('Failed to fetch result:', error);
                showMessage(t('messages.result.fetchError'), 'error');
            } finally {
                setLoading(false);
            }
        };

        if (taskId) {
            fetchResult();
            loadRecommendations(taskId, setRecommendations, setLoadingMore);
        }
    }, [taskId]);

    useEffect(() => {
        const handleScroll = () => {
            const bottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
            if (bottom && !loadingMore) {
                handleMoreClick();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loadingMore]);

    useEffect(() => {
        let reloadTimer;
        const isFromWelcomePage = document.referrer.includes('welcome-animation');

        function resetReloadTimer() {
            clearTimeout(reloadTimer);
            reloadTimer = setTimeout(() => {
                // jump to the welcome page
                window.location.href = '/shop-welcome';
            }, 60000); // 1 minute
        }

        if (isFromWelcomePage) {
            window.addEventListener('mousemove', resetReloadTimer);
            window.addEventListener('keypress', resetReloadTimer);
            resetReloadTimer();
        }

        return () => {
            if (isFromWelcomePage) {
                window.removeEventListener('mousemove', resetReloadTimer);
                window.removeEventListener('keypress', resetReloadTimer);
            }
        };
    }, []);

    const handleImageNavigation = (direction) => {
        setCurrentImageIndex((prevIndex) => {
            if (direction === 'next') {
                return (prevIndex + 1) % result.images.length;
            }
            return (prevIndex - 1 + result.images.length) % result.images.length;
        });
    };

    const handleMoreClick = () => {
        if (taskId) {
            loadRecommendations(taskId, setRecommendations, setLoadingMore);
        }
    };

    const title = 'Magic Mirror Result';
    const text = result?.text || 'Check out my amazing Magic Mirror result!';

    const currentImageUrl = result?.images?.length > 0 
        ? ApiService.getResultImageUrl(taskId, currentImageIndex)
        : null;

    const audioUrl = result?.audio 
        ? ApiService.getResultAudioUrl(taskId)
        : null;

    if (loading) {
        return (
            <div className="result-page loading">
                <TopMenu />
                <motion.div 
                    className="loading-container"
                    animate={{ rotate: 360 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                >
                    <FontAwesomeIcon icon={faMagicWandSparkles} className="loading-icon" />
                </motion.div>
            </div>
        );
    }

    return (
        <motion.div 
            className="result-page"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta property="og:description" content={text} />
                <meta property="og:image" content={currentImageUrl} />
                <meta property="og:url" content={`${config.frontend_hostname}/result/${taskId}`} />
                <meta property="og:type" content="website" />
            </Helmet>
            
            <TopMenu />
            
            <motion.div 
                className="content-container"
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
            >
                <motion.h1 
                    className="page-title"
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.3 }}
                >
                    <FontAwesomeIcon icon={faMagicWandSparkles} className="title-icon" />
                    Magic Mirror Result
                </motion.h1>

                {message && <Message message={message} type={type} />}

                <motion.div 
                    className="result-card"
                    initial={{ scale: 0.95, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 0.4 }}
                >
                    {result?.images?.length > 0 && (
                        <div className="image-gallery">
                            <motion.button 
                                className="nav-button left"
                                onClick={() => handleImageNavigation('prev')}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </motion.button>
                            
                            <motion.img
                                key={currentImageIndex}
                                src={currentImageUrl}
                                alt="Result"
                                className="result-image"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 20 }}
                                transition={{ duration: 0.3 }}
                            />

                            <motion.button 
                                className="nav-button right"
                                onClick={() => handleImageNavigation('next')}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                <FontAwesomeIcon icon={faChevronRight} />
                            </motion.button>
                        </div>
                    )}

                    <motion.div 
                        className="result-details"
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.5 }}
                    >
                        <div className="text-container">
                            <p 
                                className={`result-text ${isTextExpanded ? 'expanded' : ''}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsTextExpanded(!isTextExpanded);
                                }}
                            >
                                {result?.text}
                            </p>
                        </div>

                        {audioUrl && (
                            <div className="audio-section">
                                <FontAwesomeIcon 
                                    icon={faVolumeUp} 
                                    className={`audio-icon ${isAudioPlaying ? 'playing' : ''}`}
                                />
                                <AudioPlayer 
                                    audioSrc={audioUrl} 
                                    onPlayStateChange={setIsAudioPlaying}
                                />
                            </div>
                        )}

                        <motion.div 
                            className="share-section"
                            whileHover={{ scale: 1.02 }}
                        >
                            <ShareButton
                                taskId={taskId}
                                title={title}
                                text={text}
                                image={currentImageUrl}
                            >
                                <FontAwesomeIcon icon={faShare} className="share-icon" />
                                Share Result
                            </ShareButton>
                        </motion.div>
                    </motion.div>
                </motion.div>

                {recommendations.length > 0 && (
                    <Recommendations 
                        recommendations={recommendations} 
                        onQuickView={(item) => setSelectedItem(item)}
                    />
                )}

                {taskId && (
                    <motion.button 
                        onClick={handleMoreClick} 
                        className="more-recommendations-btn"
                        disabled={loadingMore}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {loadingMore ? (
                            <div className="loading-spinner">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="magic-wand">
                                    <path d="M50 10 L70 50 L50 90 L30 50 Z" fill="none" stroke="currentColor" strokeWidth="4" />
                                </svg>
                                Loading More Magic...
                            </div>
                        ) : (
                            <>More Recommendations</>
                        )}
                    </motion.button>
                )}
            </motion.div>

            {selectedItem && ReactDOM.createPortal(
              <QuickViewModal 
                item={selectedItem} 
                onClose={() => setSelectedItem(null)}
              />,
              document.body
            )}
        </motion.div>
    );
};

export default ResultPage;
