// File: src/main_pages/CommonPage.js
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import ApiService from '../services/apiService';
import VideoContainer from '../components/VideoContainer';
import Controls from '../components/Controls';
import TextResult from '../components/TextResult';
import Recommendations from '../components/Recommendations';
import { loadRecommendations } from '../components/Recommendations';
import TopMenu from '../components/TopMenu';
import AudioPlayer from '../components/AudioPlayer';
import EventContext from '../context/EventContext';
import ShareButton from '../components/ShareButton';
import { motion } from 'framer-motion'; 
import { useTranslation } from 'react-i18next';
import '../styles/components/CommonPage.css'; 

const CommonPage = ({
                        promptOptions,
                        fetchRecommendations,
                        pageDescription,
                        uploadMethod = ApiService.uploadPhoto,
                        showEventSelection = false,
                        showImagePreview = true,
                        showPromptOptions = true,
                        showCallToActions = true,
                        CustomControls 
                    }) => {
    const location = useLocation();
    const { i18n } = useTranslation();
    const [style, setStyle] = useState(promptOptions[0].value);
    const { event, setEvent } = useContext(EventContext);
    const [audioSrc, setAudioSrc] = useState(null);
    const [textResult, setTextResult] = useState('');
    const [recommendations, setRecommendations] = useState([]);
    const [taskId, setTaskId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [images, setImages] = useState([]);
    const [showResult, setShowResult] = useState(false); 
    const quickCaptureDelay = 3000;

    const resultViewsRef = useRef(null); 
    const videoContainerRef = useRef(null); 

    useEffect(() => {
        if (location.state?.autoCapture) {
            const captureStyle = location.state.style || 'honest'; 
            setStyle(captureStyle);

            // Wait for the VideoContainer to mount before triggering capture
            setTimeout(() => {
                if (videoContainerRef.current) {
                    videoContainerRef.current.handleCaptureClick(quickCaptureDelay); 
                }
            }, 100); 
        }
    }, [location.state]);

    useEffect(() => {
        // Scroll to the bottom of result views when textResult changes
        if (textResult && resultViewsRef.current) {
            resultViewsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [textResult]);

    useEffect(() => {
        const handleScroll = () => {
            const bottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
            if (bottom && !loadingMore) {
                handleMoreClick();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loadingMore]);

    const uploadPhoto = async (images) => {
        try {
            setLoading(true);
            setAudioSrc(null);
            setTextResult('');
            setRecommendations([]);
            
            const data = await uploadMethod(images, style, i18n.language, event);
            const { audio, text, task_id } = data;
            
            // Set task ID first as it's needed for navigation
            setTaskId(task_id);
            
            // Navigate to welcome animation page
            console.log("Navigating to welcome animation with task_id:", task_id);
            console.log("URL:", `/welcome-animation/${task_id}`);
            window.location.href = `/welcome-animation/${task_id}`;
            
        } catch (error) {
            console.error('Error uploading photo:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleMoreClick = () => {
        if (taskId) {
            loadRecommendations(taskId, setRecommendations, setLoadingMore);
        }
    };

    return (
        <div className="main-container">
            <TopMenu />
            {pageDescription && <p className="page-description">{pageDescription}</p>}

            {/* Controls and VideoContainer with Fade-Out */}
            <div className={`controls-video-container`}>
                <VideoContainer
                    ref={videoContainerRef}
                    uploadMethod={uploadPhoto}
                    actionText="Submit Photos"
                    setImages={setImages}
                    showImagePreview={showImagePreview}
                    showResult={showResult}
                    showEventSelection={showEventSelection}
                    showCallToActions={showCallToActions}
                    CustomControls={CustomControls} 
                />
                {(
                    showPromptOptions && (
                        <Controls
                            style={style}
                            setStyle={setStyle}
                            loading={loading}
                            options={promptOptions}
                            showResult={showResult}
                        />
                    )
                )}
            </div>
        </div>
    );
};

export default CommonPage;
