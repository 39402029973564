class ShopSessionManager {
    constructor() {
        this.sessionKey = null;
        this.sessionStartTime = null;
        this.sessionStatus = 'idle'; // 'idle', 'starting', 'active', 'ending'
    }

    generateSessionKey() {
        return Math.floor(1000 + Math.random() * 9000).toString();
    }

    isIdle() {
        return this.sessionStatus === 'idle';
    }

    startSession() {
        if (this.sessionStatus === 'idle') {
            this.sessionKey = this.generateSessionKey();
            this.sessionStartTime = Date.now();
            this.sessionStatus = 'starting';
            console.log('Session started:', this.sessionKey);
            return this.sessionKey;
        }
        return null; // Session already active
    }

    updateSessionStatus(currentPersonDetected) {
        if (currentPersonDetected) {
            console.log('Person detected');
            if (this.sessionStatus === 'idle') {
                this.startSession();
                return true;
            } 
            // reset session timeout
            this.sessionEndTime = Date.now() + 10000;
            return false;
        } 
        console.log('No person detected');
        if (this.sessionStatus === 'idle') {
            return false;
        } 

        if (Date.now() - this.sessionEndTime >= 10000) {
            console.log('No person detected for 10 seconds');
            this.endSession();
        }
        return false;
    }

    endSession() {
        console.log('Ending session');
        this.sessionEndTime = Date.now();
        this.sessionStatus = 'idle';
        this.sessionKey = null;
        this.sessionStartTime = null;
    }
}

export default ShopSessionManager;
