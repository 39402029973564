import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TopMenu from './TopMenu';

const Layout = () => {
    const { t } = useTranslation();

    return (
        <div>
            {/* Top Menu */}
            <TopMenu />

            {/* Main Content */}
            <main>
                <Outlet />
            </main>
        </div>
    );
};

export default Layout;