// src/components/Recommendations.js
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShoppingCart, faStar } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../axiosConfig';
import config from '../config'; 
import { decode } from 'html-entities';
import '../styles/pages/recommendations.css';

export const loadRecommendations = async (taskId, setRecommendations, setLoadingMore) => {
    setLoadingMore(true);
    try {
        const url = `${config.backend_hostname}/recommendations/${taskId}`;
        const response = await axiosInstance.post(url, { language: 'en' }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const recommendations = response.data;
        setRecommendations((prevRecommendations) => [...prevRecommendations, ...recommendations]);
    } catch (error) {
        console.error('Error getting recommendations:', error);
    } finally {
        setLoadingMore(false);
    }
};

const formatPrice = (price, currency = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency
  }).format(price);
};

const RecommendationCard = ({ item, onQuickView }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div 
      className="recommendation-card"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="recommendation-card-image">
        <img src={item.images[0]} alt={decode(item.name)} />
        {item.is_best_seller && (
          <div className="badge bestseller">
            Best Seller
          </div>
        )}
        {item.is_amazon_choice && (
          <div className="badge amazon-choice">
            Amazon's Choice
          </div>
        )}
        <div className={`recommendation-card-overlay ${isHovered ? 'visible' : ''}`}>
          <button 
            className="quick-view-btn"
            onClick={() => onQuickView(item)}
          >
            Quick View
          </button>
        </div>
      </div>
      
      <div className="recommendation-card-content">
        <div className="recommendation-card-header">
          <h3>{decode(item.name)}</h3>
          <div className="recommendation-card-actions">
            <motion.button 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="action-btn favorite"
            >
              <FontAwesomeIcon icon={faHeart} />
            </motion.button>
            <motion.button 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="action-btn cart"
            >
              <FontAwesomeIcon icon={faShoppingCart} />
            </motion.button>
          </div>
        </div>
        
        <div className="recommendation-card-details">
          <div className="recommendation-price">
            <span className="current-price">{formatPrice(item.price, item.currency)}</span>
            {item.original_price > item.price && (
              <span className="original-price">{formatPrice(item.original_price, item.currency)}</span>
            )}
          </div>
          {item.star_rating && (
            <div className="recommendation-rating">
              <FontAwesomeIcon icon={faStar} className="star-icon" />
              <span>{item.star_rating.toFixed(1)}</span>
              {item.num_ratings && (
                <span className="num-ratings">({item.num_ratings.toLocaleString()})</span>
              )}
            </div>
          )}
        </div>

        {item.delivery && (
          <div className="delivery-info">
            {item.is_prime && <span className="prime-badge">Prime</span>}
            <span>{item.delivery}</span>
          </div>
        )}
        
        <motion.a 
          href={item.link} 
          target="_blank" 
          rel="noopener noreferrer"
          className="recommendation-buy-btn"
          whileHover={{ 
            backgroundColor: '#007bff', 
            color: 'white' 
          }}
        >
          Buy Now
        </motion.a>
      </div>
    </motion.div>
  );
};

const Recommendations = ({ recommendations, onQuickView }) => {
  return (
    <div className="recommendations-container">
      <motion.h2 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="recommendations-title"
      >
        AI Curated Recommendations
      </motion.h2>
      
      <div className="recommendations-grid">
        <AnimatePresence>
          {recommendations.map((item, index) => (
            <RecommendationCard 
              key={index} 
              item={item} 
              onQuickView={onQuickView}
            />
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Recommendations;
