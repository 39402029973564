// File: src/components/TopMenu.js
import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGlobe, faSignOutAlt, faHome, faStar, faWandMagicSparkles,
    faMagic, faTshirt, faUser, faRuler, faBalanceScale, faShop,
    faBars, faTimes
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext';
import { logout } from '../services/logoutService';
import '../styles/components/TopMenu.css';
import mirrorIcon from '../assets/icons/mirror-icon-64x.png';

const LANGUAGES = [
    { code: 'en', name: 'English', flag: '🇺🇸' },
    { code: 'es', name: 'Español', flag: '🇪🇸' },
    { code: 'fr', name: 'Français', flag: '🇫🇷' },
    { code: 'br', name: 'Português', flag: '🇧🇷' },
    { code: 'he', name: 'עברית', flag: '🇮🇱' },
    { code: 'it', name: 'Italiano', flag: '🇮🇹' }
];

const MENU_ITEMS = [
    { 
        path: '/home', 
        icon: faHome, 
        titleKey: 'topMenu.home',
    },
    {
        path: '/profile',
        icon: faUser,
        titleKey: 'topMenu.profile',
    },
    { 
        path: '/mirror', 
        icon: mirrorIcon, 
        titleKey: 'topMenu.mirror',
        isImage: true
    },
    { 
        path: '/shop-dresser', 
        icon: faShop, 
        titleKey: 'topMenu.shop',
    },
    { 
        path: '/outfit-chooser', 
        icon: faBalanceScale, 
        titleKey: 'topMenu.outfitChooser',
    },
    { 
        path: '/style-meter', 
        icon: faStar, 
        titleKey: 'topMenu.styleMeter',
    }
];

const TopMenu = () => {
    const [showLanguageOptions, setShowLanguageOptions] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { currentLanguage, setLanguage } = useLanguage();

    // Responsive design check
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const toggleLanguageOptions = () => {
        setShowLanguageOptions(prev => !prev);
    };

    const handleLanguageChange = (newLanguage) => {
        setLanguage(newLanguage);
        setShowLanguageOptions(false);
    };

    const handleMobileMenuToggle = () => {
        setIsMobileMenuOpen(prev => !prev);
    };

    const currentLangObj = LANGUAGES.find(lang => lang.code === currentLanguage) || LANGUAGES[0];

    const renderMenuItem = (item) => {
        const isActive = location.pathname === item.path;
        
        return (
            <motion.div 
                key={item.path}
                className={`menu-item ${isActive ? 'active' : ''}`}
                onClick={() => {
                    navigate(item.path);
                    if (isMobile) setIsMobileMenuOpen(false);
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
            >
                {item.isImage ? (
                    <img 
                        src={item.icon} 
                        alt={t(`${item.titleKey}.title`)} 
                        className="menu-icon image-icon" 
                    />
                ) : (
                    <FontAwesomeIcon 
                        icon={item.icon} 
                        className="menu-icon" 
                    />
                )}
                <span className="menu-title">
                    {isMobile ? t(`${item.titleKey}.mobileTitle`) : t(`${item.titleKey}.title`)}
                </span>
            </motion.div>
        );
    };

    const renderDesktopMenu = () => (
        <div className="top-menu desktop-menu">
            <div className="menu-section nav-buttons">
                {MENU_ITEMS.map(item => renderMenuItem(item))}
            </div>

            <div className="menu-section language-selector">
                <motion.div 
                    className="language-toggle"
                    onClick={toggleLanguageOptions}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <span className="language-flag">{currentLangObj.flag}</span>
                    <span className="language-code">{currentLangObj.code.toUpperCase()}</span>
                </motion.div>
                
                <AnimatePresence>
                    {showLanguageOptions && (
                        <motion.div 
                            className="language-dropdown"
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                        >
                            {LANGUAGES.map((lang) => (
                                <motion.div 
                                    key={lang.code}
                                    className={`language-option ${currentLanguage === lang.code ? 'active' : ''}`}
                                    onClick={() => handleLanguageChange(lang.code)}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <span className="language-flag">{lang.flag}</span>
                                    <span className="language-name">{lang.name}</span>
                                </motion.div>
                            ))}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );

    const renderMobileMenu = () => (
        <div className="top-menu mobile-menu">
            <div className="mobile-header">
                <motion.div 
                    className="language-toggle"
                    onClick={toggleLanguageOptions}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <span className="language-flag">{currentLangObj.flag}</span>
                    <span className="language-code">{currentLangObj.code.toUpperCase()}</span>
                </motion.div>

                <motion.button 
                    className="mobile-menu-toggle"
                    onClick={handleMobileMenuToggle}
                    whileHover={{ rotate: 10 }}
                    whileTap={{ scale: 0.9 }}
                >
                    <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
                </motion.button>
            </div>

            <AnimatePresence>
                {showLanguageOptions && (
                    <motion.div 
                        className="language-dropdown mobile"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                    >
                        {LANGUAGES.map((lang) => (
                            <motion.div 
                                key={lang.code}
                                className={`language-option ${currentLanguage === lang.code ? 'active' : ''}`}
                                onClick={() => handleLanguageChange(lang.code)}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <span className="language-flag">{lang.flag}</span>
                                <span className="language-name">{lang.name}</span>
                            </motion.div>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {isMobileMenuOpen && (
                    <motion.div 
                        className="mobile-menu-dropdown"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                    >
                        <div className="mobile-menu-items">
                            {MENU_ITEMS.map(item => renderMenuItem(item))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );

    return isMobile ? renderMobileMenu() : renderDesktopMenu();
};

export default TopMenu;
