// File: src/pages/InventoryPage.js

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons'; // Import the settings icon
import ApiService from "../services/apiService";
import TopMenu from '../components/TopMenu';
import Message from '../components/Message';
import useAutoDismissMessage from '../hooks/useAutoDismissMessage';
import { useTranslation } from 'react-i18next';
import '../styles/pages/inventory.css';

const mockFetchInventory = async () => {
    // Simulate an API call
    return [
        {
            name: "UI_Trousers",
            type: "Bottom",
            description: {
                category: "Jeans",
                color: "Black",
                pattern: "Solid",
            },
        },
        {
            name: "UI_Shirt",
            type: "Top",
            description: {
                category: "Shirt",
                color: "White",
                pattern: "Striped",
            },
        },
    ];
};

const InventoryPage = () => {
    const [inventory, setInventory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [showColumnSelector, setShowColumnSelector] = useState(false); // State to toggle dropdown visibility
    const dropdownRef = useRef(null); // Ref to detect clicks outside the dropdown

    // Use the custom hook for messages
    const { message, type, showMessage, clearMessage } = useAutoDismissMessage();

    // Use translation hook
    const { t } = useTranslation();

    // Define all possible columns
    const allColumns = [
        {
            name: t('inventoryPage.columns.name'),
            selector: row => row.name,
            sortable: true,
            width: '200px', // Fixed width for better layout control
        },
        {
            name: t('inventoryPage.columns.type'),
            selector: row => row.type,
            sortable: true,
        },
        {
            name: t('inventoryPage.columns.category'),
            selector: row => row.description.category,
            sortable: true,
        },
        {
            name: t('inventoryPage.columns.color'),
            selector: row => row.description.color,
            sortable: true,
        },
        {
            name: t('inventoryPage.columns.pattern'),
            selector: row => row.description.pattern,
            sortable: true,
        },
    ];

    // Initialize selectedColumns with the first three columns or from localStorage
    useEffect(() => {
        const savedColumns = JSON.parse(localStorage.getItem('selectedColumns'));
        if (savedColumns) {
            const validColumns = allColumns.filter(col => savedColumns.includes(col.name));
            setSelectedColumns(validColumns.length > 0 ? validColumns : allColumns.slice(0, 3));
        } else {
            setSelectedColumns(allColumns.slice(0, 3));
        }
    }, []);

    // Save selectedColumns to localStorage whenever it changes
    useEffect(() => {
        const columnNames = selectedColumns.map(col => col.name);
        localStorage.setItem('selectedColumns', JSON.stringify(columnNames));
    }, [selectedColumns]);

    // Fetch inventory data on component mount
    useEffect(() => {
        const fetchInventory = async () => {
            setLoading(true);
            try {
                const response = await ApiService.getInventory();
                setInventory(response || []);
                showMessage(t('inventoryPage.messages.loadSuccess'), 'success');
            } catch (error) {
                console.error('Error fetching inventory:', error);
                showMessage(t('inventoryPage.messages.loadError'), 'error');
                // Use mock data if API call fails
                const mockData = await mockFetchInventory();
                setInventory(mockData);
            } finally {
                setLoading(false);
            }
        };
        fetchInventory();
    }, []);

    // Handle window resize to manage responsiveness if needed
    useEffect(() => {
        const handleResize = () => {
            // You can perform actions based on window size if necessary
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Handle column selection changes
    const handleColumnChange = (columnName) => {
        const column = allColumns.find(col => col.name === columnName);
        if (selectedColumns.find(col => col.name === columnName)) {
            // Remove the column
            setSelectedColumns(selectedColumns.filter(col => col.name !== columnName));
        } else {
            // Add the column
            setSelectedColumns([...selectedColumns, column]);
        }
    };

    // Toggle the visibility of the column selector dropdown
    const toggleColumnSelector = () => {
        setShowColumnSelector(!showColumnSelector);
    };

    // Close the dropdown when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowColumnSelector(false);
            }
        };
        if (showColumnSelector) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showColumnSelector]);

    return (
        <div className="main-container d-flex flex-column align-items-center justify-content-start text-center p-3">
            <TopMenu />
            <div className="inventory-page">
                <h2 className="mb-2">{t('inventoryPage.title')}</h2>
                {message && <Message message={message} type={type} />}

                {/* Row containing Total Items and Settings Icon */}
                <div className="row align-items-center mb-3">
                    {/* Left Column: Total Items */}
                    <div className="col-md-6 col-6 text-md-left text-center">
                        <p className="mb-0">{t('inventoryPage.totalItems', { count: inventory.length })}</p>
                    </div>

                    {/* Right Column: Settings Icon */}
                    <div className="col-md-6 col-6 text-md-right text-center">
                        {/* Toolbar with Settings Icon */}
                        <div className="toolbar position-relative d-inline-block">
                            <button
                                className="btn btn-secondary settings-button"
                                onClick={toggleColumnSelector}
                                aria-label={t('inventoryPage.columnSelector.ariaLabel')}
                            >
                                <FontAwesomeIcon icon={faCog} />
                            </button>

                            {/* Dropdown Menu for Column Selection */}
                            {showColumnSelector && (
                                <div className="column-selector dropdown-menu show" ref={dropdownRef}>
                                    <h5 className="dropdown-header">{t('inventoryPage.columnSelector.title')}</h5>
                                    <div className="dropdown-item-text d-flex flex-wrap justify-content-center gap-3">
                                        {allColumns.map(column => (
                                            <div key={column.name} className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={`checkbox-${column.name}`}
                                                    value={column.name}
                                                    checked={selectedColumns.some(col => col.name === column.name)}
                                                    onChange={() => handleColumnChange(column.name)}
                                                />
                                                <label className="form-check-label" htmlFor={`checkbox-${column.name}`}>
                                                    {column.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* DataTable */}
                <DataTable
                    className="react-data-table" // Ensure the class is applied
                    columns={selectedColumns}
                    data={inventory}
                    pagination
                    highlightOnHover
                    noHeader
                    theme="dark"
                    progressPending={loading}
                    responsive
                />

                {/* Add Item Button */}
                <Link to="/add-item" className="btn btn-primary mt-3">
                    <div>{t('inventoryPage.actions.addWardrobe')}</div>
                </Link>
            </div>
        </div>
    );

};

export default InventoryPage;
