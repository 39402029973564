import React, { useState, useRef } from 'react';
import CommonPage from './CommonPage';
import MultiStepShopDresser from "./multiSteps/MultiStepShopDresser";
import { useTranslation } from 'react-i18next';

const ShopPage = () => {
  const { t } = useTranslation();
  const promptOptions = [
    { value: 'zara', label: 'Zara 🌟' },
    { value: 'nike', label: 'Nike 👟' },
    { value: 'gucci', label: 'Gucci 👑' },
  ];

  return (
      <div>
        <CommonPage
            promptOptions={promptOptions}
            fetchRecommendations={true}
            pageDescription={t('pageDescriptions.shop')}
            CustomControls={<MultiStepShopDresser />} // Pass MultiStepMirror as customControls
        />
      </div>
  );
};

export default ShopPage;
