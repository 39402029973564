import React, { createContext, useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const SUPPORTED_LANGUAGES = ['en', 'es', 'fr', 'he', 'br', 'it'];

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const { i18n, t } = useTranslation();

    useEffect(() => {
        // On mount, ensure we have a valid language
        const savedLanguage = localStorage.getItem('i18nextLng');
        console.log('Saved Language:', savedLanguage);
        console.log('Current Language:', i18n.language);
        console.log('Supported Languages:', SUPPORTED_LANGUAGES);

        if (savedLanguage && SUPPORTED_LANGUAGES.includes(savedLanguage)) {
            i18n.changeLanguage(savedLanguage).then(() => {
                console.log('Language changed successfully to:', savedLanguage);
                console.log('Current translations:', t('languages'));
            }).catch((error) => {
                console.error('Failed to change language:', error);
            });
        } else {
            // If no saved language, try browser language or fall back to English
            const browserLang = navigator.language.split('-')[0];
            const defaultLang = SUPPORTED_LANGUAGES.includes(browserLang) ? browserLang : 'en';
            
            i18n.changeLanguage(defaultLang).then(() => {
                console.log('Language set to default:', defaultLang);
                localStorage.setItem('i18nextLng', defaultLang);
                console.log('Current translations:', t('languages'));
            }).catch((error) => {
                console.error('Failed to set default language:', error);
            });
        }
    }, []); // Only run on mount

    const setLanguage = useCallback(async (lang) => {
        if (SUPPORTED_LANGUAGES.includes(lang)) {
            try {
                await i18n.changeLanguage(lang);
                localStorage.setItem('i18nextLng', lang);
                // Force a reload of translations
                document.documentElement.lang = lang;
                document.documentElement.dir = lang === 'he' ? 'rtl' : 'ltr';
                
                console.log('Language changed to:', lang);
                console.log('Current translations:', t('languages'));
            } catch (error) {
                console.error('Failed to change language:', error);
            }
        }
    }, [i18n, t]);

    const value = {
        currentLanguage: i18n.language,
        setLanguage,
        supportedLanguages: SUPPORTED_LANGUAGES,
        isRTL: i18n.dir() === 'rtl'
    };

    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};
