// File: src/main_pages/HomePage.js

import React, { useState, useEffect } from 'react';
import { 
    Container, 
    Row, 
    Col, 
    Button, 
    Card 
} from 'react-bootstrap';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext';
import '../styles/HomePage.css';

import { 
    FaShoppingCart, 
    FaMagic, 
    FaUserTie, 
    FaRobot,
    FaTshirt,
    FaBox 
} from 'react-icons/fa';

// Feature Card Component with Navigation
const FeatureCard = ({ icon, title, description, route, navigate }) => (
    <motion.div 
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="feature-card mb-4"
        onClick={() => navigate(route)}
    >
        <Card className="h-100 shadow-sm border-0 cursor-pointer">
            <Card.Body className="text-center">
                <div className="feature-icon mb-3">{icon}</div>
                <Card.Title className="h5">{title}</Card.Title>
                <Card.Text className="text-muted">{description}</Card.Text>
            </Card.Body>
        </Card>
    </motion.div>
);

const HomePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isRTL } = useLanguage();
    const [showIntro, setShowIntro] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setShowIntro(false), 3000);
        return () => clearTimeout(timer);
    }, []);

    const features = [
        {
            icon: <FaShoppingCart size={50} color="#007bff" />,
            title: t('homePage.features.smartShopping.title'),
            description: t('homePage.features.smartShopping.description'),
            route: '/shop-dresser'
        },
        {
            icon: <FaMagic size={50} color="#28a745" />,
            title: t('homePage.features.aiStylist.title'),
            description: t('homePage.features.aiStylist.description'),
            route: '/outfit-chooser'
        },
        {
            icon: <FaRobot size={50} color="#ffc107" />,
            title: t('homePage.features.virtualMirror.title'),
            description: t('homePage.features.virtualMirror.description'),
            route: '/mirror'
        },
        {
            icon: <FaUserTie size={50} color="#dc3545" />,
            title: t('homePage.features.styleMeter.title'),
            description: t('homePage.features.styleMeter.description'),
            route: '/style-meter'
        },
        {
            icon: <FaBox size={50} color="#6f42c1" />,
            title: t('homePage.features.myWardrobe.title'),
            description: t('homePage.features.myWardrobe.description'),
            route: '/home-dresser'
        },
        {
            icon: <FaTshirt size={50} color="#17a2b8" />,
            title: t('homePage.features.addNewItem.title'),
            description: t('homePage.features.addNewItem.description'),
            route: '/add-item'
        }
    ];

    return (
        <div className={`homepage ${isRTL ? 'rtl' : 'ltr'}`}>
            <AnimatePresence>
                {showIntro && (
                    <motion.div 
                        className="intro-overlay"
                        initial={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <motion.h1 
                            initial={{ scale: 0.5, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ duration: 0.6 }}
                            className="display-3 text-white"
                        >
                            {t('homePage.introTitle')}
                        </motion.h1>
                    </motion.div>
                )}
            </AnimatePresence>

            <Container className="py-5">
                <Row className="align-items-center">
                    <Col md={6} className={isRTL ? 'ms-auto' : 'me-auto'}>
                        <motion.div
                            initial={{ x: isRTL ? 100 : -100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h1 className="display-4 mb-4">{t('homePage.mainTitle')}</h1>
                            <p className="hero-description lead text-muted mb-4">
                                {t('homePage.mainDescription')}
                            </p>
                            <div className="btn-container">
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    className="btn-outline-primary cta-step"
                                    onClick={() => navigate('/outfit-chooser')}
                                >
                                    {t('homePage.getStyledButton')}
                                </Button>
                                <Button 
                                    variant="outline-secondary" 
                                    size="lg"
                                    className="btn-outline-primary cta-step"
                                    onClick={() => navigate('/profile')}
                                >
                                    {t('homePage.myProfileButton')}
                                </Button>
                            </div>
                        </motion.div>
                    </Col>
                </Row>

                <Row className="my-5">
                    {features.map((feature, index) => (
                        <Col key={index} md={4}>
                            <FeatureCard 
                                {...feature} 
                                navigate={navigate} 
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default HomePage;
