// src/pages/MeasurementsPage.js

import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ApiService from '../services/apiService';
import Message from '../components/Message';
import useAutoDismissMessage from '../hooks/useAutoDismissMessage';
import TopMenu from '../components/TopMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCloudUploadAlt, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import '../styles/pages/measurements.css';
import { useTranslation } from 'react-i18next';

const MeasurementsPage = () => {
    const { t } = useTranslation();
    const [measurements, setMeasurements] = useState({
        // Numeric Measurements
        height: '',             // Height in cm
        weight: '',             // Weight in kg
        chest: '',              // Chest circumference in cm
        waist: '',              // Waist circumference in cm
        hips: '',               // Hips circumference in cm
        inseam: '',             // Inseam length in cm
        sleeve_length: '',      // Sleeve length in cm
        neck_circumference: '', // Neck circumference in cm
        shoulder_width: '',     // Shoulder width in cm
        arm_length: '',         // Arm length in cm
        leg_length: '',         // Leg length in cm
        shoe_size: '',          // Shoe size (US sizes)

        // Personal Attributes
        age: '',                // Age in years
        gender: '',             // 'male', 'female', 'non-binary', 'other'
        body_type: '',          // 'slim', 'athletic', 'average', 'curvy', 'plus-size'
        skin_tone: '',          // 'fair', 'light', 'medium', 'tan', 'dark'
        hair_color: '',         // e.g., 'brown', 'blonde'
        eye_color: '',          // e.g., 'pink', 'green'
        clothing_size: '',      // 'XS', 'S', 'M', 'L', 'XL', 'XXL'
        preferred_style: '',    // 'casual', 'formal', 'sporty', etc.
        notable_features: '',   // e.g., 'tattoo on left arm'

        // New Additions
        brand_preferences: [],
        style_preferences: [],
        exclusions: [],
        hair_type: '',
        activity_level: ''
    });

    const [activeSection, setActiveSection] = useState('upload');
    const [taskId, setTaskId] = useState(null);
    const fileInputRef = useRef(null);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);

    // Constants with translatable options
    const BRAND_OPTIONS = [
        { id: 'zara', name: t('measurementsPage.brandOptions.zara') },
        { id: 'hm', name: t('measurementsPage.brandOptions.hm') },
        { id: 'nike', name: t('measurementsPage.brandOptions.nike') },
        { id: 'adidas', name: t('measurementsPage.brandOptions.adidas') },
        { id: 'levis', name: t('measurementsPage.brandOptions.levis') },
        { id: 'calvin_klein', name: t('measurementsPage.brandOptions.calvinKlein') },
        { id: 'gucci', name: t('measurementsPage.brandOptions.gucci') },
        { id: 'uniqlo', name: t('measurementsPage.brandOptions.uniqlo') }
    ];

    const STYLE_OPTIONS = [
        { id: 'casual', name: t('measurementsPage.styleOptions.casual') },
        { id: 'formal', name: t('measurementsPage.styleOptions.formal') },
        { id: 'sporty', name: t('measurementsPage.styleOptions.sporty') },
        { id: 'minimalist', name: t('measurementsPage.styleOptions.minimalist') },
        { id: 'bohemian', name: t('measurementsPage.styleOptions.bohemian') },
        { id: 'vintage', name: t('measurementsPage.styleOptions.vintage') },
        { id: 'streetwear', name: t('measurementsPage.styleOptions.streetwear') },
        { id: 'preppy', name: t('measurementsPage.styleOptions.preppy') }
    ];

    const EXCLUSION_OPTIONS = [
        { id: 'no_leather', name: t('measurementsPage.exclusionOptions.noLeather') },
        { id: 'no_fur', name: t('measurementsPage.exclusionOptions.noFur') },
        { id: 'vegan', name: t('measurementsPage.exclusionOptions.vegan') },
        { id: 'eco_friendly', name: t('measurementsPage.exclusionOptions.ecoFriendly') }
    ];

    // Use the custom hook for messages
    const { message, type, showMessage, clearMessage } = useAutoDismissMessage();

    // Function to clean measurement values
    const cleanMeasurements = (data) => {
        // Define numeric fields
        const numericFields = [
            'height',
            'weight',
            'chest',
            'waist',
            'hips',
            'inseam',
            'sleeve_length',
            'neck_circumference',
            'shoulder_width',
            'arm_length',
            'leg_length',
            'shoe_size',
            'age',
        ];

        const cleanedMeasurements = {};
        for (const [key, value] of Object.entries(data)) {
            if (numericFields.includes(key)) {
                if (typeof value === 'string') {
                    // Remove non-numeric characters
                    const numericValue = value.replace(/[^\d.-]/g, '');
                    cleanedMeasurements[key] = numericValue === '' ? '' : parseFloat(numericValue);
                } else {
                    cleanedMeasurements[key] = value;
                }
            } else {
                // For non-numeric fields, keep the value as is
                cleanedMeasurements[key] = value;
            }
        }
        return cleanedMeasurements;
    };

    useEffect(() => {
        let isMounted = true;
        setLoading(true);

        (async () => {
            try {
                const response = await ApiService.getMeasurements();
                if (isMounted && response.measurements) {
                    const cleanedMeasurements = cleanMeasurements(response.measurements);
                    cleanedMeasurements.exclusions = cleanedMeasurements.exclusions || []
                    setMeasurements(cleanedMeasurements);
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Error fetching measurements:', error);
                    showMessage(t('messages.measurements.fetchError'), 'error');
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        })();

        return () => {
            isMounted = false;
        };
    }, []);

    const handleUpload = (event) => {
        const files = Array.from(event.target.files);
        if (files) {
            setImages((prevImages) => [...prevImages, ...files]);
        }
    };

    const handleImageRemove = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        
        // Handle multi-select preferences
        if (name === 'brand_preferences' || name === 'style_preferences') {
            setMeasurements(prevMeasurements => {
                const currentPreferences = prevMeasurements[name] || [];
                const updatedPreferences = checked 
                    ? [...currentPreferences, value]
                    : currentPreferences.filter(item => item !== value);
                
                return {
                    ...prevMeasurements,
                    [name]: updatedPreferences
                };
            });
        } else if (name === 'exclusions') {
            // Existing exclusions handling
            setMeasurements(prevMeasurements => {
                const currentExclusions = prevMeasurements.exclusions || [];
                const updatedExclusions = checked 
                    ? [...currentExclusions, value]
                    : currentExclusions.filter(item => item !== value);
                
                return {
                    ...prevMeasurements,
                    exclusions: updatedExclusions
                };
            });
        } else {
            // Handle other inputs
            setMeasurements(prevMeasurements => ({
                ...prevMeasurements,
                [name]: value
            }));
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await ApiService.saveMeasurements(taskId, measurements);
            console.log('Measurements saved:', response);
            showMessage(t('messages.measurements.saveSuccess'), 'success');
        } catch (error) {
            console.error('Error uploading measurements:', error);
            showMessage(t('messages.measurements.uploadError'), 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleAnalyse = async () => {
        if (images.length === 0) {
            showMessage(t('messages.measurements.minImageError'), 'error');
            return;
        }

        setLoading(true);
        try {
            const result = await ApiService.uploadMeasurements(images, measurements);
            console.log('Analysis:', result);
            if (result.measurements) {
                const cleanedMeasurements = cleanMeasurements(result.measurements);
                setMeasurements(cleanedMeasurements);
                showMessage(t('messages.measurements.analysisSuccess'), 'success');
            } else {
                showMessage(t('messages.measurements.noMeasurementsError'), 'error');
            }
            setTaskId(result.task_id);
        } catch (error) {
            console.error('Error analysing images:', error);
            showMessage(t('messages.measurements.analysisError'), 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const containerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: { 
                duration: 0.5,
                ease: "easeInOut"
            }
        }
    };

    const sectionVariants = {
        hidden: { opacity: 0, x: -50 },
        visible: { 
            opacity: 1, 
            x: 0,
            transition: { 
                duration: 0.3,
                ease: "easeOut"
            }
        }
    };

    const handleSaveMeasurements = async () => {
        setLoading(true);
        try {
            const response = await ApiService.saveMeasurements(taskId, measurements);
            console.log('Measurements saved:', response);
            showMessage(t('messages.measurements.saveSuccess'), 'success');
        } catch (error) {
            console.error('Error saving measurements:', error);
            showMessage(t('messages.measurements.uploadError'), 'error');
        } finally {
            setLoading(false);
        }
    };

    const renderMeasurementFields = () => {
        const numericMeasurements = [
            { key: 'height', label: t('measurementsPage.measurements.height') },
            { key: 'weight', label: t('measurementsPage.measurements.weight') },
            { key: 'chest', label: t('measurementsPage.measurements.chest') },
            { key: 'waist', label: t('measurementsPage.measurements.waist') },
            { key: 'hips', label: t('measurementsPage.measurements.hips') },
            { key: 'inseam', label: t('measurementsPage.measurements.inseam') },
            { key: 'sleeve_length', label: t('measurementsPage.measurements.sleeve_length') },
            { key: 'neck_circumference', label: t('measurementsPage.measurements.neck_circumference') },
            { key: 'shoulder_width', label: t('measurementsPage.measurements.shoulder_width') },
            { key: 'arm_length', label: t('measurementsPage.measurements.arm_length') },
            { key: 'leg_length', label: t('measurementsPage.measurements.leg_length') },
            { key: 'shoe_size', label: t('measurementsPage.measurements.shoe_size') }
        ];

        return (
            <div className="measurements-grid">
                {numericMeasurements.map(({ key, label }) => (
                    <div key={key} className="measurement-field">
                        <label 
                            htmlFor={key} 
                            className="measurement-label"
                        >
                            {label}
                        </label>
                        <input
                            type="text"
                            id={key}
                            name={key}
                            className="measurement-input"
                            value={measurements[key]}
                            onChange={(e) => handleInputChange(e)}
                            placeholder={t('measurementsPage.measurements.placeholders.enter_value')}
                            aria-label={label}
                        />
                    </div>
                ))}
            </div>
        );
    };

    const renderPersonalAttributesFields = () => {
        const personalAttributes = [
            {
                key: 'gender',
                type: 'select',
                label: t('measurementsPage.personalAttributes.gender.label'),
                placeholder: t('measurementsPage.personalAttributes.gender.placeholder'),
                options: [
                    { value: '', label: t('measurementsPage.personalAttributes.gender.placeholder') },
                    { value: 'Male', label: t('measurementsPage.personalAttributes.gender.options.male') },
                    { value: 'Female', label: t('measurementsPage.personalAttributes.gender.options.female') },
                    { value: 'Non-Binary', label: t('measurementsPage.personalAttributes.gender.options.nonBinary') },
                    { value: 'Prefer Not to Say', label: t('measurementsPage.personalAttributes.gender.options.preferNotToSay') }
                ]
            },
            {
                key: 'age',
                type: 'number',
                label: t('measurementsPage.personalAttributes.age.label'),
                placeholder: t('measurementsPage.personalAttributes.age.placeholder')
            },
            {
                key: 'body_type',
                type: 'select',
                label: t('measurementsPage.personalAttributes.bodyType.label'),
                placeholder: t('measurementsPage.personalAttributes.bodyType.placeholder'),
                options: [
                    { value: '', label: t('measurementsPage.personalAttributes.bodyType.placeholder') },
                    { value: 'Slim', label: t('measurementsPage.personalAttributes.bodyType.options.slim') },
                    { value: 'Athletic', label: t('measurementsPage.personalAttributes.bodyType.options.athletic') },
                    { value: 'Average', label: t('measurementsPage.personalAttributes.bodyType.options.average') },
                    { value: 'Curvy', label: t('measurementsPage.personalAttributes.bodyType.options.curvy') },
                    { value: 'Plus-Size', label: t('measurementsPage.personalAttributes.bodyType.options.plusSize') }
                ]
            },
            {
                key: 'skin_tone',
                type: 'select',
                label: t('measurementsPage.personalAttributes.skinTone.label'),
                placeholder: t('measurementsPage.personalAttributes.skinTone.placeholder'),
                options: [
                    { value: '', label: t('measurementsPage.personalAttributes.skinTone.placeholder') },
                    { value: 'Fair', label: t('measurementsPage.personalAttributes.skinTone.options.fair') },
                    { value: 'Light', label: t('measurementsPage.personalAttributes.skinTone.options.light') },
                    { value: 'Medium', label: t('measurementsPage.personalAttributes.skinTone.options.medium') },
                    { value: 'Tan', label: t('measurementsPage.personalAttributes.skinTone.options.tan') },
                    { value: 'Dark', label: t('measurementsPage.personalAttributes.skinTone.options.dark') }
                ]
            },
            {
                key: 'hair_type',
                type: 'select',
                label: t('measurementsPage.personalAttributes.hairType.label'),
                placeholder: t('measurementsPage.personalAttributes.hairType.placeholder'),
                options: [
                    { value: '', label: t('measurementsPage.personalAttributes.hairType.placeholder') },
                    { value: 'Straight', label: t('measurementsPage.personalAttributes.hairType.options.straight') },
                    { value: 'Wavy', label: t('measurementsPage.personalAttributes.hairType.options.wavy') },
                    { value: 'Curly', label: t('measurementsPage.personalAttributes.hairType.options.curly') },
                    { value: 'Coily', label: t('measurementsPage.personalAttributes.hairType.options.coily') },
                    { value: 'Kinky', label: t('measurementsPage.personalAttributes.hairType.options.kinky') }
                ]
            },
            {
                key: 'eye_color',
                type: 'text',
                label: t('measurementsPage.personalAttributes.eyeColor.label'),
                placeholder: t('measurementsPage.personalAttributes.eyeColor.placeholder')
            }
        ];

        return (
            <div className="personal-attributes-grid">
                {personalAttributes.map(({ key, type, label, placeholder, options }) => (
                    <div key={key} className="attribute-field">
                        <label htmlFor={key}>{label}</label>
                        {type === 'select' ? (
                            <select
                                name={key}
                                id={key}
                                value={measurements[key] || ''}
                                onChange={handleInputChange}
                            >
                                {options.map(({ value, label }) => (
                                    <option key={value} value={value}>{label}</option>
                                ))}
                            </select>
                        ) : (
                            <input
                                type={type}
                                name={key}
                                id={key}
                                value={measurements[key] || ''}
                                onChange={handleInputChange}
                                placeholder={placeholder}
                            />
                        )}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <motion.div 
            className="measurements-page"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <TopMenu />
            
            <div className="measurements-container">
                <h1 className="measurements-title">{t('measurementsPage.title')}</h1>
                <p className="measurements-subtitle">{t('measurementsPage.subtitle')}</p>

                {/* Navigation Tabs */}
                <motion.div 
                    className="section-tabs"
                    initial="hidden"
                    animate="visible"
                    variants={sectionVariants}
                >
                    <button 
                        className={`tab ${activeSection === 'upload' ? 'active' : ''}`}
                        onClick={() => handleSectionChange('upload')}
                    >
                        <FontAwesomeIcon icon={faCloudUploadAlt} /> {t('measurementsPage.navigation.upload')}
                    </button>
                    <button 
                        className={`tab ${activeSection === 'preferences' ? 'active' : ''}`}
                        onClick={() => handleSectionChange('preferences')}
                    >
                        <FontAwesomeIcon icon={faWandMagicSparkles} /> {t('measurementsPage.navigation.preferences')}
                    </button>
                    <button 
                        className={`tab ${activeSection === 'measurements' ? 'active' : ''}`}
                        onClick={() => handleSectionChange('measurements')}
                    >
                        {t('measurementsPage.navigation.measurements')}
                    </button>
                </motion.div>

                <AnimatePresence mode="wait">
                    {activeSection === 'upload' && (
                        <motion.div 
                            key="upload-section"
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={sectionVariants}
                            className="upload-section"
                        >
                            <h2>{t('measurementsPage.sections.upload.title')}</h2>
                            <p>{t('measurementsPage.sections.upload.description')}</p>
                            <div className="upload-area" onClick={triggerFileInput}>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    accept="image/*"
                                    multiple
                                    onChange={handleUpload}
                                    style={{ display: 'none' }}
                                />
                                <FontAwesomeIcon icon={faCloudUploadAlt} className="upload-icon" />
                                <p>{t('measurementsPage.sections.upload.dragAndDrop')}</p>
                            </div>

                            {images.length > 0 && (
                                <motion.div 
                                    className="images-preview"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                >
                                    {images.map((image, index) => (
                                        <motion.div 
                                            key={index} 
                                            className="image-container"
                                            whileHover={{ scale: 1.05 }}
                                            transition={{ type: "spring", stiffness: 300 }}
                                        >
                                            <img 
                                                src={URL.createObjectURL(image)} 
                                                alt={`Upload ${index}`} 
                                            />
                                            <button 
                                                onClick={() => handleImageRemove(index)}
                                                className="remove-image-btn"
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </motion.div>
                                    ))}
                                </motion.div>
                            )}

                            <div className="upload-actions">
                                <button 
                                    className="btn-analyze"
                                    onClick={handleAnalyse}
                                    disabled={loading || images.length === 0}
                                >
                                    {loading ? t('measurementsPage.sections.upload.analysing') : t('measurementsPage.sections.upload.analyze')}
                                </button>
                            </div>
                        </motion.div>
                    )}

                    {activeSection === 'preferences' && (
                        <motion.div 
                            key="preferences-section"
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={sectionVariants}
                            className="preferences-section"
                        >
                            <h2>{t('measurementsPage.sections.preferences.title')}</h2>
                            <p>{t('measurementsPage.sections.preferences.description')}</p>
                            
                            <div className="brand-preferences">
                                <h3>{t('measurementsPage.sections.preferences.brandPreferences')}</h3>
                                <div className="preference-grid">
                                    {BRAND_OPTIONS.map(brand => (
                                        <motion.label 
                                            key={brand.id} 
                                            className={`preference-item ${measurements.brand_preferences.includes(brand.id) ? 'selected' : ''}`}
                                            whileHover={{ scale: 1.05 }}
                                            transition={{ type: "spring", stiffness: 300 }}
                                        >
                                            <input
                                                type="checkbox"
                                                name="brand_preferences"
                                                value={brand.id}
                                                checked={measurements.brand_preferences.includes(brand.id)}
                                                onChange={handleInputChange}
                                            />
                                            {brand.name}
                                        </motion.label>
                                    ))}
                                </div>
                            </div>
                            
                            <div className="style-preferences">
                                <h3>{t('measurementsPage.sections.preferences.stylePreferences')}</h3>
                                <div className="preference-grid">
                                    {STYLE_OPTIONS.map(style => (
                                        <motion.label 
                                            key={style.id} 
                                            className={`preference-item ${measurements.style_preferences.includes(style.id) ? 'selected' : ''}`}
                                            whileHover={{ scale: 1.05 }}
                                            transition={{ type: "spring", stiffness: 300 }}
                                        >
                                            <input
                                                type="checkbox"
                                                name="style_preferences"
                                                value={style.id}
                                                checked={measurements.style_preferences.includes(style.id)}
                                                onChange={handleInputChange}
                                            />
                                            {style.name}
                                        </motion.label>
                                    ))}
                                </div>
                            </div>

                            <div className="exclusions">
                                <h3>{t('measurementsPage.sections.preferences.exclusions')}</h3>
                                <div className="exclusions-grid">
                                    {EXCLUSION_OPTIONS.map(exclusion => (
                                        <motion.label 
                                            key={exclusion.id} 
                                            className={`exclusion-item ${measurements.exclusions.includes(exclusion.id) ? 'selected' : ''}`}
                                            whileHover={{ scale: 1.05 }}
                                            transition={{ type: "spring", stiffness: 300 }}
                                        >
                                            <input
                                                type="checkbox"
                                                name="exclusions"
                                                value={exclusion.id}
                                                checked={measurements.exclusions.includes(exclusion.id)}
                                                onChange={handleInputChange}
                                            />
                                            {exclusion.name}
                                        </motion.label>
                                    ))}
                                </div>
                            </div>

                            <div className="preferences-actions">
                                <motion.button
                                    className="save-preferences-btn"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={handleSubmit}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ 
                                        type: "spring", 
                                        stiffness: 300, 
                                        damping: 10 
                                    }}
                                >
                                    {t('measurementsPage.sections.preferences.savePreferences')}
                                </motion.button>
                            </div>
                        </motion.div>
                    )}

                    {activeSection === 'measurements' && (
                        <motion.div 
                            key="measurements-section"
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={sectionVariants}
                            className="measurements-section"
                        >
                            <h2>{t('measurementsPage.sections.measurements.title')}</h2>
                            <p>{t('measurementsPage.sections.measurements.description')}</p>
                            
                            {renderMeasurementFields()}
                            {renderPersonalAttributesFields()}

                            <motion.button
                                className="save-preferences-btn"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={handleSaveMeasurements}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ 
                                    type: "spring", 
                                    stiffness: 300, 
                                    damping: 10 
                                }}
                            >
                                {t('measurementsPage.sections.measurements.saveMeasurements')}
                            </motion.button>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>

            {message && <Message message={message} type={type} />}
        </motion.div>
    );
};

export default MeasurementsPage;
